import { IInviteOverview, IMemberOverview } from '../../../../lib/types';
import React, { PropsWithChildren } from 'react';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { SendInvitesContainer } from '../../../domains/invite/send/send-invites-form.container';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { IAdminProps } from '../../../_core/core.types';
import { ITableHeaderCell, ITableOptionsConfig, ITableRowValues } from '../../../_core/table/table.types';

interface IStaffTableProps
  extends PropsWithChildren,
    IAdminProps,
    ITableOptionsConfig<IInviteOverview | IMemberOverview> {
  inviteStaff: boolean;
  setInviteStaff: React.Dispatch<React.SetStateAction<boolean>>;
  staff: IStaffTableRowValues[];
}

export interface IStaffTableRowValues extends ITableRowValues {
  invite?: IInviteOverview;
  member?: IMemberOverview;
}

export const StaffTable: React.FC<IStaffTableProps> = ({ children, inviteStaff, setInviteStaff, staff, ...rest }) => {
  const headers: ITableHeaderCell[] = [
    { content: 'Name', sortKey: 'user' },
    { content: 'Email' },
    { content: 'Roles', sortKey: 'roles' },
    { content: 'Status', sortKey: 'deletedAt' },
    { content: 'Departments', sortKey: 'department' },
    { content: 'Locations', sortKey: 'location' },
    { content: '' }
  ];

  return (
    <>
      <TableWithActions {...rest} bordered selectable loading={!staff} headers={headers} values={staff}>
        {children}
      </TableWithActions>
      <RIDialog open={inviteStaff} setOpen={setInviteStaff}>
        <SendInvitesContainer close={() => setInviteStaff(false)} />
      </RIDialog>
    </>
  );
};
