import { OutputBlockData } from '@editorjs/editorjs';
import { ISectionData, SectionType } from '../section/section.types';
import { uniqueId } from 'lodash';

export const CUSTOM_BLOCK_WRAPPER_CLASS = 'custom-block-wrapper';
export const SCROLL_INTO_VIEW_CLASS = 'scroll-into-view';
export const SHOW_BOTH_TOOLBAR_OPTIONS_ON_EMPTY_CLASS = 'toolbar-show-on-empty';

export const inputClassesLineHeightStr = 'py-1 sm:text-xs sm:leading-6';

const textClassesStr = `${inputClassesLineHeightStr} text-gray-900`;
export const inputClassesStr = `${textClassesStr} ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 block rounded-md border-0 shadow-sm ring-1 disabled:text-gray-400 disabled:ring-gray-300 disabled:bg-gray-100 disabled:placeholder:text-transparent`;
export const underlinedInputClassesStr =
  'border-0 border-b !ring-0 rounded-none border-dashed border-gray-300 pb-[3px]';

export const textClasses = textClassesStr.split(' ');
export const inputClasses = inputClassesStr.split(' ');
export const underlinedInputClasses = underlinedInputClassesStr.split(' ');

export const inputErrorClasses = ['ring-red-300', 'placeholder:text-red-400'];

export enum BlockType {
  BranchMulti = 'branch-multi',
  BranchYesNo = 'branch',
  Checkbox = 'checkbox',
  DocProvide = 'docProvide',
  DocUpload = 'upload',
  DocUploadList = 'uploadList',
  Header = 'header',
  Link = 'link',
  Question = 'question',
  QuestionGroup = 'questionGroup',
  Radio = 'radio',
  RepeatSection = 'repeatSection',
  Section = 'section',
  Text = 'paragraph',
  YesNo = 'yesNo'
}

export const BlockTitles: Record<BlockType, string> = {
  [BlockType.BranchMulti]: 'Branch - Multi',
  [BlockType.BranchYesNo]: 'Branch - Yes/No',
  [BlockType.Checkbox]: 'Checkbox',
  [BlockType.DocProvide]: 'Doc - Provide',
  [BlockType.DocUpload]: 'Doc - Upload',
  [BlockType.DocUploadList]: 'Doc - Upload List',
  [BlockType.Header]: 'Header',
  [BlockType.Link]: 'Link',
  [BlockType.Question]: 'Question',
  [BlockType.QuestionGroup]: 'Question Group',
  [BlockType.Radio]: 'Radio',
  [BlockType.RepeatSection]: 'Question Group Multi',
  [BlockType.Section]: 'Section',
  [BlockType.Text]: 'Text',
  [BlockType.YesNo]: 'Yes/No'
};

export const DEFAULT_SECTION_FORM_DATA: OutputBlockData<string, ISectionData> = {
  id: uniqueId(),
  type: 'section',
  data: {
    completed: false,
    filled: false,
    required: false,
    localTitle: '1)',
    label: 'First Section',
    forceOpen: true,
    outputData: { blocks: [] },
    type: SectionType.SECTION
  }
};
