import { useTemplates } from '../../../domains/template/template.service';
import { Loader } from '../../../_core/loader.component';
import { TemplateTableNestedContainer } from './template-table.nested.container';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { TEMPLATE_TYPE } from '../../../../lib/types';

interface ITemplateTableContainer extends IAdminProps, IPersonalCompanyProps {}

export const TemplateTableContainer: React.FC<ITemplateTableContainer> = (props) => {
  const { templates } = useTemplates([TEMPLATE_TYPE.BLOCK, TEMPLATE_TYPE.REQUEST, TEMPLATE_TYPE.SECTION]);

  if (!templates) return <Loader />;
  return <TemplateTableNestedContainer templates={templates?.filter((t) => t.company || !t.deletedAt)} {...props} />;
};
