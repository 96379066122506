import EditorJs, { OutputData } from '@editorjs/editorjs';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useUpdateRequest, useUpdateRequestAsClient } from '../../../domains/request/request.service';
import { IRequest, IRequestBlock, ITemplate, TEMPLATE_TYPE } from '../../../../lib/types';
import { IEditorRefProps } from '../../../_pages/FormBuilderPage/form-editor.types';
import { showError } from '../../../../lib/utils';
import { createRoot } from 'react-dom/client';
import { Button } from '../../../_core/button/button.component';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useInterval } from 'usehooks-ts';
import _ from 'lodash';
import { EditorSaveResult } from '../types';
import toast from 'react-hot-toast';
import { useUpdateTemplate } from '../../../domains/template/template.service';

// Util hooks
export const useFocusedBlockTracking = ({ editorRef, editorblock }: IEditorRefProps) => {
  // TODO: Add focused block tracking for possible side panel

  useEffect(() => {
    const editorElement = document.getElementById(editorblock);
    const onClick = (e: MouseEvent) => {
      // Prevent bubble up of click event to parent section blocks
      e.stopImmediatePropagation();

      if (editorElement && editorRef.current && e.type === 'click') {
        const blockIndex = editorRef.current.blocks.getCurrentBlockIndex();
        if (blockIndex >= 0) {
          const block = editorRef.current.blocks.getBlockByIndex(blockIndex);
          document.querySelector('.focused-block')?.classList.remove('focused-block');
          editorElement.querySelector(`div[data-id="${block?.id}"]`)?.classList.add('focused-block');
        }
      }
    };

    if (editorElement) {
      editorElement.addEventListener('click', onClick);
    }

    return () => {
      editorElement?.removeEventListener('click', onClick);
    };
  }, [editorRef, editorblock]);
};

const filterIgnoredEntries = ([key, value]: [string, any]) =>
  !['__typename', 'time', 'blockIndex', 'totalBlocks', 'requestSent', 'id', 'open'].includes(key) &&
  value !== undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepCompare = (a?: any, b?: any) => {
  if (!a && !b) return true;
  if (!a || !b) {
    // console.log('One value does not exist', { a, b });
    return false;
  }
  const aEntries = Object.entries(a).filter(filterIgnoredEntries);
  const bKeys = Object.entries(b).filter(filterIgnoredEntries);

  if (aEntries.length !== bKeys.length) {
    // console.log('Keycount mismatch', { a, b });
    return false;
  }

  for (const [key, value] of aEntries) {
    if (Array.isArray(value)) {
      if (!deepCompareArr(value, b[key])) {
        // console.log('Deep compare arrays failed', { key, a: value, b: b[key] });
        return false;
      }
    } else if (typeof value === 'object') {
      if (!(key in b)) {
        // console.log('Missing key in b', { key, a, b });
        return false;
      } else if (!deepCompare(value, b[key])) {
        // console.log('Deep compare failed', { key, a: value, b: b[key] });
        return false;
      }
    } else if (!_.isEqual(value, b[key])) {
      // console.log('Values unequal', { key, a: value, b: b[key] });
      return false;
    }
  }

  return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepCompareArr = (a?: any[], b?: any[]) => {
  // console.log('Deep compare arr', { a, b });
  if (a?.length !== b?.length) return false;

  for (let i = 0; i < (a?.length ?? 0); i++) {
    if (!deepCompare(a?.[i], b?.[i])) {
      // console.log('Failed on', { i, a: a?.[i], b: b?.[i] });
      return false;
    }
  }

  return true;
};

export const useSaveEditor = ({
  canSave,
  ref,
  request,
  template,
  token
}: {
  canSave: boolean;
  ref?: MutableRefObject<EditorJs | undefined>;
  request?: IRequest;
  template?: ITemplate;
  token?: string;
}) => {
  const [latestData, setLatestData] = useState<OutputData | undefined>();
  const [hasChanged, setHasChanged] = useState(false);

  const { updateRequestAsClient, loading: updatingAsClient } = useUpdateRequestAsClient({
    requestId: request?._id ?? '',
    token: token ?? ''
  });
  const { updateRequest, loading: updatingRequest } = useUpdateRequest({ _id: request?._id ?? '' });

  const { updateTemplate, loading: updatingTemplate } = useUpdateTemplate({
    _id: template?._id ?? '',
    type: template?.type ?? TEMPLATE_TYPE.BLOCK
  });

  const checkIfChanged = useCallback(async (): Promise<EditorSaveResult> => {
    try {
      // const start = new Date();

      if (!ref?.current) return { hasChanged: false };

      // Wait for editor to be ready
      await ref.current.isReady;

      // Validate editor data is accessible
      const badStateToastId = 'editor-bad-state-toast';
      if (!('save' in ref.current)) {
        toast.error('Editor is in an error state. Please wait for the error to resolve or refresh the page.', {
          duration: 600000,
          id: badStateToastId
        });
        return { hasChanged: false };
      } else toast.dismiss(badStateToastId);

      const data = await ref?.current?.save(true);
      const hasChanged = !deepCompareArr(request?.blocks ?? template?.blocks, data?.blocks);
      // console.log('Time to complete', (new Date().getTime() - start.getTime()) / 1000, 'seconds');
      return { data, hasChanged };
    } catch (err) {
      showError('Failed to determine if data has changed', err as Error);
      return {};
    }
  }, [ref, request?.blocks, template?.blocks]);

  const onSave = useCallback(async (): Promise<EditorSaveResult> => {
    try {
      if (hasChanged && latestData && (request || template)) {
        const blocks = latestData.blocks as IRequestBlock[];

        let succeeded = false;

        if (template) succeeded = !!(await updateTemplate({ blocks, isGlobal: !template.company })).data;

        if (request)
          if (token) succeeded = !!(await updateRequestAsClient(blocks)).data;
          else succeeded = !!(await updateRequest({ blocks })).data;

        if (succeeded) setHasChanged(false);
        else throw new Error('Failed to save');
      }

      return { data: latestData, hasChanged };
    } catch (err) {
      showError('Failed to save', err as Error);
      return {};
    }
  }, [hasChanged, latestData, request, template, token, updateRequest, updateRequestAsClient, updateTemplate]);

  useInterval(() => {
    if (canSave) {
      onSave().then((result) => {
        if (result.hasChanged) toast.success('Saved');
        return result;
      });
    }
  }, 5000);

  useInterval(() => {
    if (canSave || !latestData) {
      checkIfChanged().then((r) => {
        if (r.hasChanged) setHasChanged(true);

        // Guarantee we populate latest data if it has yet to be set
        setLatestData((prev) => (!prev || r.hasChanged ? r.data : prev));
      });
    }
  }, 1000);

  return { hasChanged, latestData, onSave, saving: updatingRequest || updatingAsClient || updatingTemplate };
};

// Util functions
export const renderWithBlockFocusWrapper = (content: HTMLDivElement): HTMLDivElement => {
  return content;
};

export interface IRenderOpenToggleParams {
  additionalClass?: string;
  append?: boolean;
  dark?: boolean;
  onOpenToggle?: () => void;
  open: boolean;
  openToggleId: string;
  parent?: HTMLDivElement;
  replaceSelector?: string;
}

export const SECTION_OPEN_TOGGLE_CLASS = 'section-open-toggle';

export const renderOpenToggle = ({
  additionalClass,
  append,
  dark,
  onOpenToggle,
  open,
  openToggleId,
  parent,
  replaceSelector
}: IRenderOpenToggleParams) => {
  const openToggle = document.createElement('div');
  openToggle.id = openToggleId;
  openToggle.classList.add(SECTION_OPEN_TOGGLE_CLASS, `open-${open}`);
  if (additionalClass) openToggle.classList.add(additionalClass);

  const openRoot = createRoot(openToggle);
  const iconContainerClass = `m-auto border rounded-md ${dark ? 'border-secondary bg-secondary' : 'border-black'}`;
  const iconSize = 18;
  openRoot.render(
    <Button
      hideEndMargin
      icon={
        open ? (
          <div className={iconContainerClass}>
            <ChevronDownIcon height={iconSize} width={iconSize} color={dark ? 'white' : ''} />
          </div>
        ) : (
          <div className={iconContainerClass}>
            <ChevronRightIcon height={iconSize} width={iconSize} color={dark ? 'white' : ''} />
          </div>
        )
      }
      className="ce-toolbar__button"
      onClick={onOpenToggle ? () => onOpenToggle() : undefined}
      type="button"
      variant="outline"
    />
  );

  if (replaceSelector) {
    const wrapper = document.querySelector(replaceSelector);
    wrapper?.querySelector('#' + openToggleId)?.remove();

    if (append) wrapper?.append(openToggle);
    else wrapper?.prepend(openToggle);
  } else {
    if (append) parent?.append(openToggle);
    else parent?.prepend(openToggle);
  }
};
