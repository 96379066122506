import { StarIcon } from '@heroicons/react/20/solid';
import { stickyTableCellClass } from './table-consts';
import { Button } from '../button/button.component';

interface ITableCheckboxProps {
  checked?: boolean;
  header?: boolean;
  onChange?: (_: boolean) => void;
  variant?: 'default' | 'star';
}

export const TableCheckbox = ({ checked, header, onChange, variant = 'default' }: ITableCheckboxProps) => {
  const content = (
    <div className="flex items-center">
      {variant === 'default' ? (
        <input
          type="checkbox"
          className={`w-4 h-4 text-blue-600 bg-gray-100 rounded focus:ring-blue-500 focus:ring-2 ${
            header ? 'border-gray-500' : 'border-gray-300'
          }`}
          checked={!!checked}
          onChange={onChange ? (e) => onChange(e.target.checked) : undefined}
        />
      ) : (
        <Button
          icon={<StarIcon height={12} width={12} fill={checked ? 'orange' : 'white'} />}
          onClick={() => onChange?.(!checked)}
          variant="secondary"
        />
      )}
    </div>
  );

  if (header) return <th className={`w-8 px-2 py-1 ${stickyTableCellClass}`}>{content}</th>;
  return <td className="w-8 px-2 py-1">{content}</td>;
};
