import { Formik, FormikErrors, FormikProps } from 'formik';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IRequestGroup, ITemplateBaseDetails } from '../../../../lib/types';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { ICreateRequestGroupProps } from '../request-group.types';
import { EditRequestGroupFormFields, IEditRequestGroupFormFieldProps } from './edit-request-group-form-fields';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';

interface IEditRequestGroupFormProps
  extends IRIFormProps<ICreateRequestGroupProps>,
    IPersonalCompanyProps,
    IEditRequestGroupFormFieldProps {
  currUserId: string;
  group?: IRequestGroup;
  template?: ITemplateBaseDetails;
}

export const EditRequestGroupForm = ({
  onSubmit,
  group,
  template,
  currUserId,
  ...rest
}: IEditRequestGroupFormProps) => {
  const validateForm = (values: ICreateRequestGroupProps) => {
    const errors: FormikErrors<ICreateRequestGroupProps> = {};
    if (!values.name) errors.name = 'Required';
    return errors;
  };

  return (
    <Formik
      initialValues={
        group
          ? ({
              name: group.name,
              assignedClients:
                group.assignedClients?.map(({ _id: value, name, user }) => ({
                  label: name ?? user.name ?? user.email,
                  secondaryLabel: user.email,
                  value
                })) ?? [],
              assignedStaff:
                group.assignedStaff?.map(({ _id: value, user }) => ({
                  label: user.name ?? user.email,
                  secondaryLabel: user.email,
                  value
                })) ?? [],
              canSelfServe: group.canSelfServe,
              deadline: group.deadline,
              expiration: group.expiration,
              notes: group.notes,
              requestType: group.requestType ? [{ label: group.requestType.type, value: group.requestType._id }] : []
            } as ICreateRequestGroupProps)
          : ({
              name: '',
              canSelfServe: false,
              assignedClients: [],
              assignedStaff: [],
              requestType: [],
              templateId: template
                ? ([
                    {
                      value: template._id,
                      label: template.title,
                      secondaryLabel: template.description
                    }
                  ] as IMultiSelectOption[])
                : []
            } as ICreateRequestGroupProps)
      }
      onSubmit={onSubmit}
      validate={validateForm}
      validateOnBlur={false}
    >
      {(props: FormikProps<ICreateRequestGroupProps>) => (
        <EditRequestGroupFormFields
          {...props}
          {...rest}
          createdById={group?.createdBy._id ?? currUserId}
          hasBeenCreated={!!group}
        >
          <FormButton loading={props.isSubmitting} text={group ? 'Update' : 'Create'} />
        </EditRequestGroupFormFields>
      )}
    </Formik>
  );
};
