import { PropsWithChildren, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

// Auth0
import { useAuth0 } from '@auth0/auth0-react';

// Apollo
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import toast from 'react-hot-toast';

import { BASE_API_URL } from '../lib/utils';
import { useLogout } from '../components/domains/auth/auth.service';
import { Loader } from '../components/_core/loader.component';
import { tokenVar } from './token.service';

let loggingLinkCount = 0;

export const RIApolloProvider = ({ children }: PropsWithChildren) => {
  const [client, setClient] = useState<ApolloClient<unknown> | null>(null);
  const [clientIsAuthenticated, setClientIsAuthenticated] = useState(false);
  const [requiresAuthClient, setRequiresAuthClient] = useState<'yes' | 'no' | null>(null);
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const { handleLogout } = useLogout();

  useEffect(() => {
    if (!isLoading) {
      setRequiresAuthClient(isAuthenticated ? 'yes' : 'no');
    }
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    const httpLink = createHttpLink({
      uri: `${BASE_API_URL}/graphql`
    });

    const removeTypenameLink = removeTypenameFromVariables();

    const authLink = setContext(async (_, { headers }) => {
      const options = { headers: { ...headers } };
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();

          if (token) {
            options.headers.Authorization = `Bearer ${token}`;
            tokenVar(options.headers.Authorization);
          }
        } catch (error) {
          await handleLogout({
            afterExecution: () =>
              toast.error('Re-authentication required, please log in again.', { duration: 5000, id: 'unauthenticated' })
          });
        }
      }

      return options;
    });

    const isDev = import.meta.env.VITE_ENV === 'dev';

    const loggingLink = new ApolloLink((operation, forward) => {
      if (forward == null) throw new Error('Must not be a terminating link');

      const startTime = Date.now();
      const currentRequestNum = loggingLinkCount;
      loggingLinkCount += 1;
      const query = operation.operationName;

      return forward(operation).map((data) => {
        if (isDev) {
          const duration = Date.now() - startTime;
          console.debug(`GraphQL Network Response #${currentRequestNum}`, { query, duration: duration + ' ms' });
        }
        return data;
      });
    });

    const nextClient = new ApolloClient({
      link: loggingLink.concat(removeTypenameLink).concat(authLink).concat(httpLink),
      cache: new InMemoryCache({
        typePolicies: {
          RequestBlock: { keyFields: false },
          RequestBlockTemplate: { keyFields: false }
        }
      }),
      connectToDevTools: isDev
    });

    setClient(nextClient);
    setClientIsAuthenticated(isAuthenticated);
  }, [handleLogout, isAuthenticated, getAccessTokenSilently, isLoading]);

  if (isLoading || !client || !requiresAuthClient || (isAuthenticated && !clientIsAuthenticated)) return <Loader />;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const GraphqlProvider = () => {
  return (
    <RIApolloProvider>
      <Outlet />
    </RIApolloProvider>
  );
};
