import { IOnClickProps } from '../click.types';

export enum TableFilterKeys {
  OWNERSHIP = 'Ownership',
  STATUS = 'Status',
  TYPE = 'Type'
}

export interface ITableSelectableProps {
  selectable?: boolean; // TODO: Need to implement selected functionality
}

export interface ITableCellProps extends React.PropsWithChildren {
  header?: boolean;
  key?: string;
  rowClass?: ITableRowClassConfig | null;
}

export type SortDirection = 'asc' | 'desc';
export type SortedKeys = Record<string, SortDirection>;

export type SortMethod<T extends object> =
  | 'default'
  | 'date'
  | 'string'
  | ((a: T, b: T, sorted: SortDirection) => number);

export type SortMethodTuple<T extends object> = [SortMethod<T>, ((_: T) => any) | void];

export interface ISortIdentifiable {
  sortKey?: string;
}

export interface ITableSortedCellProps extends ISortIdentifiable {
  sorted?: SortDirection;
}

export interface ITableHeaderCell extends ISortIdentifiable {
  content: string;
}

export interface ITableRowClassConfig {
  cell?: string;
  content?: string;
}

export interface IBaseTableRowProps extends ITableSelectableProps, IOnClickProps {
  onSelect?: (_selected: boolean) => void;
  rowClasses?: (ITableRowClassConfig | null)[] | null;
  selected?: boolean;
}

export interface ITableRowProps extends IBaseTableRowProps, IFavoritableTableProps {
  index: number;
  values: ITableCellProps[];
}

export interface ITableHeaderRowProps extends IBaseTableRowProps, IFavoritableTableProps {
  onSortSelect?: (_: ISortIdentifiable) => void;
  sortedKeys?: SortedKeys;
  values: ITableHeaderCell[];
}

export interface IdentifiableSelected {
  isSelected: boolean;
  rowId?: string;
}

export interface ITableMultiselectActionProps<T> {
  clearSelected: () => void;
  getSelectedModels: () => T[] | undefined;
}

export interface ITableFilterOption<T> {
  containerClass?: string;
  placeholder?: string;
  hidden?: boolean;
  title: string;
  options: string[];
  labeler?: (_: string) => string | undefined;
  value: string[];
  matcher: (_: T, __: string[]) => boolean;
}

export interface ISetSelectedFilterProps {
  title: string;
  value: string[];
}

export interface ITableFilterConfig<T> {
  options: ITableFilterOption<T>[];
  setSelectedFilters: (_: ISetSelectedFilterProps) => void;
}

export interface ITableModeConfig {
  options: string[];
  onChange: (_: string) => void;
  value: string;
}

export interface ITableSearchConfig {
  onChange: (_: string) => void;
  value: string;
}

export interface IFavoritableTableProps {
  favoritable?: boolean;
  favorited?: boolean[];
  onFavorite?: (_: number, __: boolean) => void;
}

export interface ITableOptionsConfig<T extends Object> extends IFavoritableTableProps {
  bordered?: boolean;
  filterConfig?: ITableFilterConfig<T>;
  loading?: boolean;
  modeConfig?: ITableModeConfig;
  onRowClick?: (_: number) => void;
  onSelect?: (_index: number, _selected: boolean) => void;
  onSelectAll?: (_: boolean) => void;
  onSortSelect?: (__: ISortIdentifiable) => void;
  searchable?: boolean;
  searchConfig?: ITableSearchConfig;
  selected?: boolean[];
  sortedKeys?: SortedKeys;
}

export interface ITableRowValues {
  rowId?: string;
  values: ITableCellProps[];
}

export interface ITableProps<T extends object> extends ITableSelectableProps, ITableOptionsConfig<T> {
  headers: ITableHeaderCell[];
  onRowClick?: (_: number) => void;
  rowClasses?: (ITableRowClassConfig | null)[] | null;
  values: ITableRowValues[];
}
