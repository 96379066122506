import { PropsWithChildren } from 'react';
import { ArrowLeftCircleIcon, Cog6ToothIcon } from '@heroicons/react/20/solid';
import { REQUEST_STATUS } from '../../lib/types';
import { Button } from '../_core/button/button.component';

interface IPageActionWrapper extends PropsWithChildren {
  actionButtons?: JSX.Element;
  onBack: () => void;
  onEdit?: () => void;
  readOnly?: boolean;
  status?: REQUEST_STATUS;
  title?: JSX.Element;
}

export const PageActionWrapper = ({
  actionButtons,
  children,
  onBack,
  onEdit,
  readOnly,
  status,
  title
}: IPageActionWrapper) => {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center sticky">
      <div id="request-actions" className="w-full bg-white sticky drop-shadow-lg top-10 z-50">
        <div className="max-w-[90%] w-full flex items-center justify-between py-1 mx-auto">
          <div className="flex flex-1 gap-4 items-center">
            {!readOnly && (
              <>
                <Button
                  slim
                  className="hover:opacity-50 hover:ring hover:ring-inset hover:ring-black"
                  containerClass="h-7"
                  icon={<ArrowLeftCircleIcon height={24} width={24} color="black" />}
                  onClick={onBack}
                  size="fit"
                  variant="custom"
                />
                {!!onEdit && (
                  <Button
                    slim
                    className="hover:opacity-50 hover:ring hover:ring-inset hover:ring-black"
                    containerClass="h-7"
                    icon={<Cog6ToothIcon height={24} width={24} color="black" />}
                    onClick={onEdit}
                    size="fit"
                    variant="custom"
                  />
                )}
              </>
            )}
            {readOnly ? (
              <>
                {status === REQUEST_STATUS.CLOSED && (
                  <h3 className="bg-secondary py-1 px-2 text-sm text-white">Closed</h3>
                )}
              </>
            ) : (
              title
            )}
          </div>
          <div className="relative flex h-7">{actionButtons}</div>
        </div>
      </div>
      {children}
    </div>
  );
};
