import { useCallback, useState } from 'react';
import moment from 'moment';
import { FormContainer } from '../../../_core/form/form-container.component';
import { useClientOverviews } from '../../clients/client.service';
import { useSelectedRequestTypes } from '../../request-type/request-type.service';
import { Loader } from '../../../_core/loader.component';
import { useCompanyMembers } from '../../member/member.service';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../_routes';
import { IRequestGroup, ITemplateBaseDetails } from '../../../../lib/types';
import { ICloseableDialogProps } from '../../../_core/core.types';
import { showError } from '../../../../lib/utils';
import { useFetchCurrUser } from '../../users/user.service';
import toast from 'react-hot-toast';
import { ICreateRequestGroupCallParams, ICreateRequestGroupProps } from '../request-group.types';
import { EditRequestGroupForm } from './edit-request-group.component';
import { useCreateRequestGroup, useUpdateRequestGroup } from '../request-group.service';

interface IEditRequestGroupContainer extends Partial<ICloseableDialogProps> {
  group?: IRequestGroup;
  template?: ITemplateBaseDetails;
}

export const EditRequestGroupContainer = ({ close, group, template }: IEditRequestGroupContainer) => {
  const navigate = useNavigate();

  const [submitError, setSubmitError] = useState<string | null>(null);

  const { user } = useFetchCurrUser();

  const { clients } = useClientOverviews();
  const { requestTypes } = useSelectedRequestTypes();
  const { members } = useCompanyMembers();

  const { createRequestGroup } = useCreateRequestGroup();
  const { updateRequestGroup } = useUpdateRequestGroup({ _id: group?._id ?? '' });

  const handleEditRequest = useCallback(
    async (params: ICreateRequestGroupCallParams) => {
      // Create or update request
      if (group) {
        const result = await updateRequestGroup(params);
        toast.success('Updated bulk request successfully!');
        if (result.data && close) close();
      } else {
        try {
          const result = await createRequestGroup(params);
          if (result.data) navigate(ROUTE_PATHS.REQUEST_GROUP + '/' + result.data.createRequestGroup._id);
          else setSubmitError('Failed to create bulk request');
        } catch (err) {
          showError('Failed to create bulk request', err as Error);
        }
      }
    },
    [close, createRequestGroup, navigate, group, updateRequestGroup]
  );

  const handleSubmit = useCallback(
    async (props: ICreateRequestGroupProps) => {
      try {
        const params: ICreateRequestGroupCallParams = {
          ...props,
          assignedClients: props.assignedClients.filter((v) => v !== null).map(({ value }) => value),
          assignedStaff: props.assignedStaff.filter((v) => v !== null).map(({ value }) => value),
          deadline: props.deadline ? moment(props.deadline).toISOString() : undefined,
          expiration: props.expiration ? moment(props.expiration).toISOString() : undefined,
          requestType: props.requestType?.length ? props.requestType[0]?.value ?? null : undefined,
          template: props.template?.length ? props.template[0]?.value ?? null : undefined
        };

        return await handleEditRequest(params);
      } catch (err) {
        showError('Failed editing request group', err as Error);
      }
    },
    [clients, confirm, handleEditRequest]
  );

  return (
    <>
      <FormContainer error={submitError} setError={setSubmitError} title={`${group ? 'Edit' : 'New'} Bulk Request`}>
        {!user || !clients || !requestTypes || !members ? (
          <Loader />
        ) : (
          <EditRequestGroupForm
            clients={clients}
            group={group}
            requestTypes={requestTypes}
            staff={members}
            onSubmit={handleSubmit}
            currUserId={user._id}
            template={template}
          />
        )}
      </FormContainer>
    </>
  );
};
