import { useCallback, useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { useClientOverviews, useUpdateClient } from '../../clients/client.service';
import { EditRequestForm } from './edit-request.component';
import { useSelectedRequestTypes } from '../../request-type/request-type.service';
import { Loader } from '../../../_core/loader.component';
import { ICreateRequestCallParams, ICreateRequestProps } from '../request.types';
import { useCreateRequest, useUpdateRequest } from '../request.service';
import { useCompanyMembers } from '../../member/member.service';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../_routes';
import { IClient, IRequest, ITemplateBaseDetails } from '../../../../lib/types';
import { ICloseableDialogProps } from '../../../_core/core.types';
import { useEntityOverviews } from '../../entities/entity.service';
import { showError } from '../../../../lib/utils';
import { useFetchCurrUser } from '../../users/user.service';
import { useLazyEntityMembers } from '../../entity-members/entity-members.service';
import { useConfirm } from '../../../_core/confirm/confirm.utils';
import toast from 'react-hot-toast';
import moment from 'moment';

interface IEditRequestContainer extends Partial<ICloseableDialogProps> {
  request?: IRequest;
  template?: ITemplateBaseDetails;
}

export const EditRequestContainer = ({ close, request, template }: IEditRequestContainer) => {
  const navigate = useNavigate();

  const [submitError, setSubmitError] = useState<string | null>(null);

  const { user } = useFetchCurrUser();

  const { clients } = useClientOverviews();
  const { entities } = useEntityOverviews();
  const { fetchEntityMembers } = useLazyEntityMembers();
  const { requestTypes } = useSelectedRequestTypes();
  const { members } = useCompanyMembers();

  const { createRequest } = useCreateRequest();
  const { updateRequest } = useUpdateRequest({ _id: request?._id ?? '' });
  const { updateClient } = useUpdateClient();

  const { ConfirmationDialog, confirm } = useConfirm(
    'Are you sure you want to add these contacts to the selected company?'
  );

  const handleEditRequest = useCallback(
    async (params: ICreateRequestCallParams) => {
      console.log('Performing request operation');

      // Create or update request
      if (request) {
        const result = await updateRequest(params);
        toast.success('Updated request successfully!');
        if (result.data && close) close();
      } else {
        try {
          const result = await createRequest(params);
          if (result) navigate(ROUTE_PATHS.REQUEST + '/' + result._id);
          else setSubmitError('Failed to create request');
        } catch (err) {
          showError('Failed to create request', err as Error);
        }
      }
    },
    [close, createRequest, navigate, request, updateRequest]
  );

  const handleSubmit = useCallback(
    async (props: ICreateRequestProps) => {
      try {
        const params: ICreateRequestCallParams = {
          ...props,
          assignedTo: props.assignedTo?.length ? props.assignedTo[0]?.value ?? null : undefined,
          ccClients: props.ccClients?.filter((v) => v !== null).map(({ value }) => value),
          entityId: props.entity?.length ? props.entity[0]?.value ?? null : undefined,
          deadline: props.deadline ? moment(props.deadline).toISOString() : undefined,
          requestType: props.requestType?.length ? props.requestType[0]?.value ?? null : undefined,
          staff: props.staff?.filter((v) => v !== null).map(({ value }) => value),
          templateId: props.templateId?.length ? props.templateId[0]?.value ?? null : undefined
        };

        // Validate client membership in entity
        if (params.entityId) {
          const { data } = await fetchEntityMembers({ entityId: params.entityId });
          if (data?.entityMembers) {
            // Gather all clients belonging to the request, and determine their entity membership status
            const includedClients = [params.assignedTo, ...(params.ccClients ?? [])]
              .map((c) => (c ? clients?.find((client) => client._id === c) : null))
              .filter((v) => v) as IClient[];

            const clientsMissingMembership = includedClients.reduce((acc: IClient[], curr) => {
              const foundClient = data.entityMembers.find(
                ({ deletedAt, user }) => !deletedAt && user._id === curr.user._id
              );
              if (!foundClient) acc.push(curr);
              return acc;
            }, []);

            // If any clients are not yet part of the entity, then prompt user to confirm they want to add those clients to the entity
            if (clientsMissingMembership.length) {
              const confirmed = await confirm(
                `Add [${clientsMissingMembership.map((c) => c.name ?? c.user.name ?? c.user.email).join(', ')}] to ${
                  entities?.find((e) => e._id === params.entityId)?.name ?? 'Company'
                }?`
              );

              // On confirm, add all clients to entity and then perform request operation
              if (confirmed) {
                console.log('Updating client memberships');
                await Promise.all(
                  clientsMissingMembership.map((c) => updateClient({ _id: c._id, entities: [params.entityId ?? ''] }))
                );
                return await handleEditRequest(params);
              } else return;
            }
          }
        }

        return await handleEditRequest(params);
      } catch (err) {
        showError('Failed editing request', err as Error);
      }
    },
    [clients, confirm, entities, fetchEntityMembers, handleEditRequest, updateClient]
  );

  return (
    <>
      <FormContainer error={submitError} setError={setSubmitError} title={`${request ? 'Edit' : 'New'} Request`}>
        {!user || !clients || !entities || !requestTypes || !members ? (
          <Loader />
        ) : (
          <EditRequestForm
            clients={clients}
            entities={entities}
            request={request}
            requestTypes={requestTypes}
            staff={members}
            onSubmit={handleSubmit}
            currUserId={user._id}
            template={template}
          />
        )}
      </FormContainer>
      <ConfirmationDialog />
    </>
  );
};
