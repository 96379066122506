import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import EditorJs, { OutputData } from '@editorjs/editorjs';

import { IRequest, ITemplate, REQUEST_STATUS, TEMPLATE_TYPE } from '../../lib/types';
import { FormEditor } from './FormBuilderPage/form-editor.component';
import { RIDialog } from '../_core/dialog/dialog.component';
import { convertRemToPixels, sleep, usePrev } from '../../lib/utils';
import { useExitPrompt } from '../_core/utils/browser';
import { PageActionWrapper } from './page-action-wrapper.component';
import { useSaveEditor } from '../_editor/_core/utils/editor.utils';
import {
  CreateTemplateContainer,
  ICreateTemplateContainerProps
} from '../domains/template/create/create-template-form.container';
import { ICreateBlockTemplateParams } from '../_editor/_core/create-template-block';
import { useConfirm } from '../_core/confirm/confirm.utils';
import { DownloadRequestAssetsButtonContainer } from '../domains/request/download-request-assets-button';
import { Button } from '../_core/button/button.component';
import toast from 'react-hot-toast';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { EditorSaveResult } from '../_editor/_core/types';

interface IEditorPage {
  ActionButtons: (_: {
    afterClear: () => void;
    confirmClear: (__?: string) => Promise<boolean>;
    hasChanged: boolean;
    onSave: () => Promise<EditorSaveResult>;
    onTogglePreview: (___: boolean) => void;
  }) => React.ReactElement;
  TitleComponent: JSX.Element;
  canSave: boolean;
  data: OutputData;
  disabled?: boolean;
  isClosed?: boolean;
  isSent?: boolean;
  onBack: () => void;
  onEdit: () => void;
  openFirstSection?: boolean;
  readOnly?: boolean;
  request?: IRequest;
  template?: ITemplate;
  title: string;
  token?: string;
}

export const EditorPage = ({
  canSave,
  isClosed,
  readOnly,
  request,
  template,
  ActionButtons,
  TitleComponent,
  ...rest
}: IEditorPage) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const editorRef = useRef<EditorJs>();

  const [canRefreshData, setCanRefreshData] = useState(false);
  const [createTemplate, setCreateTemplate] = useState<ICreateTemplateContainerProps | null>(null);
  const [isReady, setIsReady] = useState(false);

  const [previewing, setPreviewing] = useState(
    readOnly ?? searchParams.has('mode') ? searchParams.get('mode') === 'preview' : !!rest.isSent
  );

  useEffect(
    () => setPreviewing(readOnly ?? searchParams.has('mode') ? searchParams.get('mode') === 'preview' : !!rest.isSent),
    [readOnly, rest.isSent, searchParams]
  );

  const { hasChanged, onSave, saving } = useSaveEditor({ canSave, ref: editorRef, request, template });

  const scrollBlockIntoView = (id: string, retries = 0) => {
    if (retries > 10) {
      console.log('Abandon attempt to scroll block into view');
      return;
    }

    const scrollToBlock = document.querySelector(`div[data-id="${id}"]`);
    if (scrollToBlock) scrollToBlock.scrollIntoView({ behavior: 'instant' });
    else sleep(100).then(() => scrollBlockIntoView(id, retries + 1));
  };

  const onTogglePreview = useCallback(
    (state?: boolean) => {
      // Top of section area based on sticky header height and typical size of section header
      const topOfSectionArea = convertRemToPixels(4.75) + convertRemToPixels(2.5);

      const blocks = document.querySelectorAll('.ce-block');
      const firstBlockInView = [...blocks.entries()].find((b) => {
        const e = b[1] as HTMLElement;
        const bounds = e.getBoundingClientRect();
        return bounds.y > topOfSectionArea;
      })?.[1] as HTMLElement;

      setPreviewing((v) => {
        const newPreviewing = state ?? !v;
        setSearchParams({ mode: newPreviewing ? 'preview' : 'edit' });
        return newPreviewing;
      });

      editorRef.current?.readOnly?.toggle(state);

      if (firstBlockInView?.dataset.id) sleep(100).then(() => scrollBlockIntoView(firstBlockInView.dataset.id ?? ''));
    },
    [editorRef]
  );

  const prevRequestStatus = usePrev(request?.status);

  useEffect(() => {
    if (request?.status && isReady && editorRef.current)
      if (prevRequestStatus === REQUEST_STATUS.CLOSED && !isClosed)
        // Toggle edit mode when re-opening request
        onTogglePreview(false);
      // Toggle ready only on closed
      else if (prevRequestStatus !== REQUEST_STATUS.CLOSED && isClosed && !previewing) onTogglePreview(true);
  }, [editorRef, isClosed, isReady, onTogglePreview, prevRequestStatus, previewing, request?.status]);

  const onCreateBlockTemplate = ({ data, type = TEMPLATE_TYPE.SECTION }: ICreateBlockTemplateParams) => {
    setCreateTemplate({ data: { blocks: [{ data: data.data, type: data.type }] }, type, version: EditorJs.version });
  };

  const { ConfirmationDialog: ConfirmClearDialog, confirm: confirmClear } = useConfirm(
    'Are you sure you want to clear template?'
  );

  useExitPrompt({ isDirty: hasChanged, onCancel: () => onSave() });

  const afterClear = useCallback(() => {
    setCanRefreshData(true);
    onTogglePreview(false);

    // TODO: Refreshing the page is a short-term workaround, ideally we find a way for prod editors to be re-rendered succesfully on external data changes without failing in prod
    window.location.reload();
  }, [onTogglePreview]);

  const target = request ? 'Request' : 'Template';
  return (
    <>
      <PageActionWrapper
        onBack={rest.onBack}
        onEdit={rest.onEdit}
        title={TitleComponent}
        actionButtons={
          <div className="relative flex h-7 gap-4">
            <ActionButtons
              afterClear={afterClear}
              confirmClear={confirmClear}
              hasChanged={hasChanged}
              onSave={onSave}
              onTogglePreview={onTogglePreview}
            />
            <div className="inline-block">
              <Button
                slim
                onClick={() => onTogglePreview()}
                size="medium"
                text={previewing ? 'Edit' : 'Preview'}
                variant="secondary"
              />
            </div>
            {canSave && (
              <div className="inline-block">
                <Button
                  slim
                  disabled={!hasChanged}
                  loading={saving}
                  onClick={async () =>
                    onSave().then((data) => {
                      toast.success(
                        data.hasChanged ? `Saved ${target.toLowerCase()} successfully` : target + ' already up to date'
                      );
                      return data;
                    })
                  }
                  size="medium"
                  text="Save"
                  icon={
                    hasChanged ? (
                      <ExclamationCircleIcon width={16} height={16} color="gold" style={{ marginRight: 2 }} />
                    ) : undefined
                  }
                />
              </div>
            )}
            <DownloadRequestAssetsButtonContainer
              ignoreSpacing
              request={request}
              template={template}
              onSave={onSave}
              forceLabelsOnDocProvide={previewing}
            />
          </div>
        }
      >
        <div
          id="request-content"
          className="mx-auto max-w-[90%] w-full p-4 pt-1 flex flex-1 flex-col m-6 bg-white rounded-2xl"
        >
          <div className="shadow-customShort mx-auto mb-1 p-2 w-full relative flex items-center justify-center rounded-t">
            <h2 className="text-center text-xl text-secondary font-bold">{rest.title}</h2>
          </div>
          {/* TODO: Fix overflow-x here, so whole editor can scroll */}
          <div className="shadow-custom mx-auto w-full rounded-b">
            {/* TODO: Does each bit of data need to be memoized to prevent the Editor re-renders? */}
            {/* TODO: Should force re-render on company switch */}
            <FormEditor
              canRefreshData={canRefreshData}
              setCanRefreshData={setCanRefreshData}
              data={rest.data}
              disabled={rest.disabled}
              editorblock={'editorjs'}
              editorRef={editorRef}
              onCreateTemplate={onCreateBlockTemplate}
              onReady={() => setIsReady(true)}
              onSave={onSave}
              openFirstSection={rest.openFirstSection}
              previewing={previewing}
              quickCreateTitle={template && template.type === TEMPLATE_TYPE.BLOCK ? undefined : 'add section'}
              type={template && template.type === TEMPLATE_TYPE.BLOCK ? 'section' : 'base'}
              readOnly={!!readOnly}
              token={rest.token}
            />
          </div>
        </div>
      </PageActionWrapper>
      {!!createTemplate && (
        <RIDialog setOpen={(o) => setCreateTemplate((v) => (o ? v : null))}>
          <CreateTemplateContainer close={() => setCreateTemplate(null)} {...createTemplate} />
        </RIDialog>
      )}
      <ConfirmClearDialog />
    </>
  );
};
