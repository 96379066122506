import { Form, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { IClientOverview, IMemberOverview, IRequestType, TEMPLATE_TYPE } from '../../../../lib/types';
import { PropsWithChildren, useMemo, useState } from 'react';
import { ICreateRequestGroupProps } from '../request-group.types';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';
import { useTemplates } from '../../template/template.service';
import { OrderedOwnershipLevels, Ownership } from '../../../../lib/global.types';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { CreateClientInviteContainer } from '../../invite/create-client/create-client-invite-form.container';

export interface IEditRequestGroupFormFieldProps {
  clients: IClientOverview[];
  requestTypes: IRequestType[];
  staff: IMemberOverview[];
  hasBeenCreated?: boolean;
}

export const EditRequestGroupFormFields = ({
  clients,
  requestTypes,
  staff,
  createdById,
  hasBeenCreated,

  // Formik props
  children,
  setValues,
  values
}: IEditRequestGroupFormFieldProps &
  FormikProps<ICreateRequestGroupProps> &
  PropsWithChildren & { createdById: string }) => {
  const [newContactData, setNewContactData] = useState<{ email: string; fieldName: 'assignedClients' } | null>(null);

  // Fetch templates
  const { templates } = useTemplates([TEMPLATE_TYPE.REQUEST]);

  const templateOptions = useMemo(
    () =>
      templates
        ?.filter((t) => t.company || !t.deletedAt)
        ?.map(
          (t) =>
            ({
              value: t._id,
              label: t.title,
              secondaryLabel: t.description,
              group: t.company ? Ownership.ORGANIZATION : Ownership.GLOBAL
            } as IMultiSelectOption)
        ),
    [templates]
  );

  const staticOptions = useMemo(
    () => ({
      clients: clients
        .filter((c) => !c.deletedAt && !c.user.deletedAt)
        .map(
          (d) =>
            ({
              label: d.name ?? d.user.name,
              secondaryLabel: d.user.email,
              value: d._id
            } as IMultiSelectOption)
        ),
      requestTypes: requestTypes.filter((rt) => !rt.deletedAt).map((l) => ({ label: l.type, value: l._id })),
      staff: staff
        .filter((s) => !s.deletedAt && !s.user.deletedAt && s.user._id !== createdById)
        .map((d) => ({ label: d.user.name, secondaryLabel: d.user.email, value: d._id }))
    }),
    [clients, createdById, requestTypes, staff]
  );

  return (
    <>
      <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
        <FormInputWithLabel
          autoFocus
          id="name"
          name="name"
          label="Bulk Request Name"
          placeholder="Bulk Request Name"
          value={values.name}
        />
        <FormInputWithLabel
          id="assignedClients"
          name="assignedClients"
          label="Assigned Contacts"
          value={values.assignedClients}
          type="multiselect"
          placeholder="Assigned Contacts"
          multiSelectProps={{
            options: staticOptions.clients,
            nullable: true,
            canAdd: true,
            onAdd: (email) => setNewContactData({ email, fieldName: 'assignedClients' })
          }}
        />
        <FormInputWithLabel
          id="assignedStaff"
          name="assignedStaff"
          label="Share Bulk Request With"
          value={values.assignedStaff}
          type="multiselect"
          placeholder="Share with Team Members"
          multiSelectProps={{ options: staticOptions.staff, nullable: true, selectAll: true }}
        />
        {/* TODO: Discuss default assigned with Kevin */}
        <FormInputWithLabel
          id="requestType"
          name="requestType"
          label="Request Type"
          value={values.requestType}
          type="multiselect"
          placeholder="Select request type"
          multiSelectProps={{ options: staticOptions.requestTypes, singleSelect: true, nullable: true }}
        />
        <FormInputWithLabel
          id="deadline"
          name="deadline"
          label="Deadline"
          placeholder="Enter deadline date"
          value={values.deadline}
          type="date"
        />
        <FormInputWithLabel id="notes" name="notes" label="Notes" placeholder="Optional notes" value={values.notes} />
        <FormInputWithLabel
          id="canSelfServe"
          name="canSelfServe"
          label="Enable Self Serve Link"
          value={values.canSelfServe}
          type="checkbox"
        />
        {!!values.canSelfServe && (
          <FormInputWithLabel
            id="expiration"
            name="expiration"
            label="Expiration"
            placeholder="Enter expiration date"
            value={values.expiration}
            type="date"
          />
        )}
        {!hasBeenCreated && (
          <FormInputWithLabel
            id="template"
            name="template"
            label="Template"
            placeholder="Select Template"
            type="multiselect"
            value={values.template}
            multiSelectProps={{
              nullable: true,
              singleSelect: true,
              options: templateOptions,
              groupOrder: OrderedOwnershipLevels
            }}
            disabled={!templates}
          />
        )}
        {children}
      </Form>
      {!!newContactData && (
        <RIDialog open={!!newContactData} setOpen={(o) => setNewContactData((d) => (o ? d : null))} locked>
          <CreateClientInviteContainer
            email={newContactData.email}
            close={() => setNewContactData(null)}
            afterSuccess={(newClient) => {
              setValues({
                ...values,
                [newContactData.fieldName]: [
                  ...(values[newContactData.fieldName] ?? []),
                  {
                    value: newClient._id,
                    label: newClient.name ?? newClient.user.name,
                    secondaryLabel: newClient.user.email
                  }
                ]
              });
              setNewContactData(null);
            }}
          />
        </RIDialog>
      )}
    </>
  );
};
