import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import EditorJs, { OutputData } from '@editorjs/editorjs';
import { ChatBubbleLeftIcon } from '@heroicons/react/20/solid';

import {
  CreateTemplateContainer,
  ICreateTemplateContainerProps
} from '../../domains/template/create/create-template-form.container';
import { IRequest, REQUEST_NOTIFY_OPTION, REQUEST_STATUS, TEMPLATE_TYPE } from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { ROUTE_PATHS } from '../../../_routes';
import { EditRequestContainer } from '../../domains/request/edit/edit-request.container';
import { RequestActionButton } from '../../domains/request/request-action-button';
import { showError } from '../../../lib/utils';
import { SendRequestMessageContainer } from '../../domains/conversation/send-request-message/send-request-message.container';
import { useSendRequest } from '../../domains/request/request.service';
import { EditorSaveResult } from '../../_editor/_core/types';
import { RequestHeaderText } from './request-header-text.component';
import { EditorPage } from '../editor-page.component';
import { DEFAULT_SECTION_FORM_DATA } from '../../_editor/_core/editor.const';

interface IRequestPage {
  canSave: boolean;
  editorRef: React.MutableRefObject<EditorJs | undefined>;
  readOnly?: boolean;
  request: IRequest;
  token?: string;
}

export const RequestPage = ({ canSave, readOnly, request, token }: IRequestPage) => {
  const isClosed = request.status === REQUEST_STATUS.CLOSED;
  const isSent = useMemo(() => request.status === REQUEST_STATUS.SENT, [request.status]);
  const showRespond = isSent && !!token;

  const navigate = useNavigate();
  const location = useLocation();

  const [template, setTemplate] = useState<ICreateTemplateContainerProps | null>(null);
  const [editRequest, setEditRequest] = useState(false);
  const [showConversation, setShowConversation] = useState(false);
  const [preppingSendRequest, setPreppingSendRequest] = useState(false);

  const { sendRequest, loading: sendingRequest } = useSendRequest();

  const onCreateRequestTemplate = ({ data }: EditorSaveResult) => {
    if (data) setTemplate({ data, type: TEMPLATE_TYPE.REQUEST, version: data.version ?? EditorJs.version });
    else toast.error('Failed to save request and create template');
  };

  const editorData: OutputData = {
    time: request.time,
    version: request.version,
    blocks: request.blocks ?? [DEFAULT_SECTION_FORM_DATA]
  };

  return (
    <>
      <EditorPage
        canSave={canSave}
        data={editorData}
        disabled={isClosed || !!request.deletedAt}
        isClosed={isClosed}
        onBack={() => navigate('backPath' in location.state ? location.state.backPath : ROUTE_PATHS.DASHBOARD)}
        onEdit={() => setEditRequest(true)}
        readOnly={readOnly}
        request={request}
        title={request.name ?? ''}
        TitleComponent={
          <>
            <RequestHeaderText>
              TO:{' '}
              {request.assignedTo?.name || request.assignedTo?.user.name
                ? (request.assignedTo?.name ?? request.assignedTo?.user.name) + ' '
                : ''}
              {request.assignedTo ? `(${request.assignedTo?.user.email})` : 'None'}
            </RequestHeaderText>
            {!!request.entity && <RequestHeaderText>{request.entity.name}</RequestHeaderText>}
          </>
        }
        ActionButtons={({ afterClear, hasChanged, onSave, onTogglePreview }) => (
          <>
            {showRespond && (
              <div className="inline-block">
                <Button
                  slim
                  hideEndMargin
                  icon={<ChatBubbleLeftIcon width={24} height={24} className="mr-2" />}
                  variant="secondary"
                  onClick={() => setShowConversation(true)}
                  text="Respond"
                />
              </div>
            )}
            {!readOnly && (
              <>
                <div className={`w-36 inline-block ${showRespond ? 'ml-4' : ''}`}>
                  <RequestActionButton
                    slim
                    loading={sendingRequest}
                    hideViewAction
                    reloadPageState
                    request={request}
                    togglePreview={onTogglePreview}
                    onClearRequest={afterClear}
                    onCreateTemplate={() =>
                      onSave()
                        .then(onCreateRequestTemplate)
                        .catch((err) => showError('Failed to save request', err))
                    }
                    onSave={onSave}
                    onSendRequest={() => {
                      setPreppingSendRequest(true);
                      setShowConversation(true);
                    }}
                    onResendRequest={() => setShowConversation(true)}
                    hasChanged={hasChanged}
                  />
                </div>
              </>
            )}
          </>
        )}
      />
      {!!template && (
        <RIDialog setOpen={(o) => setTemplate((v) => (o ? v : null))}>
          <CreateTemplateContainer close={() => setTemplate(null)} {...template} />
        </RIDialog>
      )}
      {!!editRequest && (
        <RIDialog open={!!editRequest} setOpen={setEditRequest}>
          <EditRequestContainer close={() => setEditRequest(false)} request={request} />
        </RIDialog>
      )}
      {!!showConversation && (
        <RIDialog open={!!showConversation} setOpen={setShowConversation}>
          <SendRequestMessageContainer
            close={() => {
              setPreppingSendRequest(false);
              setShowConversation(false);
            }}
            request={request}
            token={token}
            forceSendAll={preppingSendRequest}
            onSubmit={
              preppingSendRequest
                ? ({ message, notify = [REQUEST_NOTIFY_OPTION.ALL], replyTo, subject }) =>
                    sendRequest({ _id: request._id, customMessage: message, replyTo, notify, subject })
                : undefined
            }
          />
        </RIDialog>
      )}
    </>
  );
};
