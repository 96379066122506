import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../_core/button/button.component';
import { IButtonDropdownOptionProps } from '../../_core/button/button-dropdown-option-component';
import { IRequestOverview, REQUEST_STATUS } from '../../../lib/types';
import { ROUTE_PATHS } from '../../../_routes';
import {
  useClearRequest,
  useDeleteRequest,
  useDuplicateRequest,
  useRestoreRequest,
  useUpdateRequest
} from './request.service';
import toast from 'react-hot-toast';
import { showError } from '../../../lib/utils';
import { useConfirm } from '../../_core/confirm/confirm.utils';
import { useDialogOptions } from '../../_core/dialog-options-modal/dialog-options-modal.utils';
import { EditorSaveResult } from '../../_editor/_core/types';

export interface IRequestActionProps {
  hasChanged?: boolean;
  hideViewAction?: boolean;
  last?: boolean;
  loading?: boolean;
  onClearRequest?: () => void;
  onCreateTemplate?: () => void;
  onSave?: () => Promise<EditorSaveResult>;
  onSendRequest?: () => void;
  onResendRequest?: () => void;
  reloadPageState?: boolean;
  request: IRequestOverview;
  slim?: boolean;
  togglePreview?: (_: boolean) => void;
}

export const RequestActionButton: React.FC<IRequestActionProps> = ({
  hasChanged,
  hideViewAction,
  last,
  loading,
  onClearRequest,
  onCreateTemplate,
  onSave,
  onSendRequest,
  onResendRequest,
  reloadPageState,
  request,
  slim,
  togglePreview
}) => {
  const navigate = useNavigate();

  const { clearRequest, loading: clearingRequest } = useClearRequest(request, () => {
    // TODO: Refreshing the page is a short-term workaround, ideally we find a way for prod editors to be re-rendered succesfully on external data changes without failing in prod
    if (reloadPageState) window.location.reload();
  });

  const { duplicateRequest, loading: duplicatingRequest } = useDuplicateRequest(request);
  const { deleteRequest, loading: deletingRequest } = useDeleteRequest();
  const { restoreRequest, loading: restoringRequest } = useRestoreRequest();
  const { updateRequest, loading: updatingRequest } = useUpdateRequest(request);

  const { ConfirmationDialog: ConfirmClearDialog, confirm: confirmClear } = useConfirm(
    'Are you sure you want to clear request?'
  );

  const { Dialog: CopyDialog, open: openCopyDialog } = useDialogOptions({
    title: 'Duplicate Request',
    message: 'Do you want to clear answers from the created copy?',
    buttons: [
      { title: 'No', clickResult: false },
      { title: 'Yes', clickResult: true }
    ]
  });

  const viewAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () =>
        navigate(ROUTE_PATHS.REQUEST + '/' + request._id, {
          state: { backPath: window.location.pathname + window.location.search }
        }),
      text: 'View Request'
    }),
    [navigate, request._id]
  );

  const duplicateAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () =>
        openCopyDialog().then(async (clear) => {
          if (hasChanged && onSave) await onSave();
          duplicateRequest(clear).then(({ data }) => {
            // NOTE: Can't use navigate function here because request cache gets messed up and previous requests blocks are maintained on new request (likely due to editor load issue?)
            if (data?.duplicateRequest) window.location.href = ROUTE_PATHS.REQUEST + '/' + data.duplicateRequest._id;
            else toast.error('Failed to duplicate request for unknown reason');
          });
        }),
      text: 'Duplicate Request'
    }),
    [duplicateRequest, hasChanged, navigate, onSave, openCopyDialog]
  );

  const clearAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () =>
        confirmClear().then((clear) => {
          if (clear) {
            if (onClearRequest) onClearRequest();
            clearRequest().then(() => {
              // TODO: Refreshing the page is a short-term workaround, ideally we find a way for prod editors to be re-rendered succesfully on external data changes without failing in prod
              if (reloadPageState) window.location.reload();
            });
          }
        }),
      text: 'Clear Request'
    }),
    [clearRequest, confirmClear, onClearRequest]
  );

  const createTemplateAction: IButtonDropdownOptionProps | null = useMemo(
    () =>
      onCreateTemplate
        ? ({ onClick: onCreateTemplate, text: 'Save copy as template' } as IButtonDropdownOptionProps)
        : null,
    [onCreateTemplate]
  );

  const updateStatusAction: IButtonDropdownOptionProps = useMemo(() => {
    const isClosed = request.status === REQUEST_STATUS.CLOSED;
    return {
      onClick: () => {
        togglePreview?.(!isClosed);
        updateRequest({ status: isClosed ? REQUEST_STATUS.SENT : REQUEST_STATUS.CLOSED });
      },
      text: isClosed ? 'Re-open Request' : 'Close Request'
    };
  }, [request.status, updateRequest, togglePreview]);

  const onRestoreRequest = useCallback(async () => {
    if (request.deletedAt)
      try {
        await restoreRequest(request);
        toast.success(`Restored Request`);
      } catch (err) {
        showError('Unable to restore request', err as Error);
      }
  }, [request, restoreRequest]);

  const restoreRequestAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onRestoreRequest, text: 'Restore' }),
    [onRestoreRequest]
  );

  const onDeleteRequest = useCallback(async () => {
    try {
      await deleteRequest(request);
      toast.success(`Deleted Request`);
    } catch (err) {
      showError('Unable to delete request', err as Error);
    }
  }, [deleteRequest, request]);

  const deleteRequestAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onDeleteRequest, text: 'Delete' }),
    [onDeleteRequest]
  );

  const resendAction: IButtonDropdownOptionProps | null = useMemo(
    () => (onResendRequest ? { onClick: onResendRequest, text: 'Re-send Request' } : null),
    [onResendRequest]
  );

  const sendAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () =>
        onSendRequest
          ? request.assignedTo
            ? onSendRequest()
            : toast.error('Must assign a contact to send request')
          : {},
      text: 'Send Request'
    }),
    [onSendRequest, request.assignedTo]
  );

  const unsendAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () => updateRequest({ status: REQUEST_STATUS.DRAFT }),
      text: 'Unsend Request'
    }),
    [updateRequest]
  );

  const options = useMemo(() => {
    const newOptions: IButtonDropdownOptionProps[] = [];
    if (!hideViewAction) newOptions.push(viewAction);
    if (!request.deletedAt) {
      newOptions.push(clearAction, duplicateAction);
      if (createTemplateAction) newOptions.push(createTemplateAction);
      if (request.status === REQUEST_STATUS.DRAFT && onSendRequest) newOptions.push(sendAction);
      if (request.status === REQUEST_STATUS.SENT) {
        if (resendAction) newOptions.push(resendAction);
        newOptions.push(unsendAction);
      }

      if (request.status && [REQUEST_STATUS.SENT, REQUEST_STATUS.CLOSED].includes(request.status))
        newOptions.push(updateStatusAction);
    }

    newOptions.push(request.deletedAt ? restoreRequestAction : deleteRequestAction);

    return newOptions;
  }, [
    hideViewAction,
    viewAction,
    request.deletedAt,
    request.status,
    restoreRequestAction,
    deleteRequestAction,
    clearAction,
    duplicateAction,
    createTemplateAction,
    onSendRequest,
    sendAction,
    resendAction,
    unsendAction,
    updateStatusAction
  ]);

  return (
    <>
      <Button
        slim={slim}
        dropdown={{ options, up: last }}
        text="ACTION"
        size="large"
        loading={
          loading || deletingRequest || restoringRequest || updatingRequest || clearingRequest || duplicatingRequest
        }
      />
      <ConfirmClearDialog />
      <CopyDialog />
    </>
  );
};
