import { useCallback, useState } from 'react';
import { inputClassesLineHeightStr } from '../_core/editor.const';
import { RadioOption } from './radio-option.component';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';
import { flushSync } from 'react-dom';

interface IRadioOptionsList {
  onUpdate: (_: string[]) => void;
  options: string[];
  setOptions: React.Dispatch<React.SetStateAction<string[]>>;
  tagClass: string;
}

export const RadioOptionsList = ({ onUpdate, options, setOptions, tagClass }: IRadioOptionsList) => {
  const handleRemove = useCallback(
    (tag: string) =>
      setOptions((prev) => {
        const newOptions = prev.filter((t) => t !== tag);
        onUpdate(newOptions);
        return newOptions;
      }),
    [setOptions]
  );

  // Setup drag and drop handlers
  const [_, setLastDrop] = useState<{ source: string; target: string } | null>(null);

  const onDrop = ({ location, source }: { location: DragLocationHistory; source: { data: any } }) => {
    const target = location.current.dropTargets[0];
    if (!target) return;

    const sourceData = source.data;
    const targetData = target.data;

    if (!('tag' in source.data) || !('tag' in target.data)) return;

    const newDrop = { source: sourceData.tag, target: target.data.tag as string };

    // TODO: This can still be optimized furter
    setLastDrop((prevDrop) => {
      if (prevDrop?.source !== newDrop.source || prevDrop?.target !== newDrop.target) {
        setOptions((prevData) => {
          const indexOfSource = prevData.findIndex((t) => t === sourceData.tag);
          const indexOfTarget = prevData.findIndex((t) => t === targetData.tag);
          if (indexOfTarget < 0 || indexOfSource < 0) return prevData;

          const newData = reorderWithEdge({
            list: prevData,
            startIndex: indexOfSource,
            indexOfTarget,
            closestEdgeOfTarget: extractClosestEdge(targetData),
            axis: 'horizontal'
          });
          onUpdate(newData);

          flushSync(() => {
            // Being simple and just querying for the item after the drop.
            // We could use react context to register the element in a lookup,
            // and then we could retrieve that element after the drop and use
            // `triggerPostMoveFlash`. But this gets the job done.
            const element = document.querySelector(`[data-handler-id="${newDrop.source}"]`);
            if (element instanceof HTMLElement) triggerPostMoveFlash(element);
          });
          return newData;
        });
        return newDrop;
      } else return prevDrop;
    });
  };

  monitorForElements({
    canMonitor: ({ source }) => 'tag' in source.data,
    onDrop
  });

  return (
    <div className={`absolute top-0 right-0 draggable ${inputClassesLineHeightStr}`}>
      {options.map((tag, i) => (
        <RadioOption key={'tag-' + tag + i} {...{ i, onRemove: () => handleRemove(tag), tag, tagClass }} />
      ))}
    </div>
  );
};
