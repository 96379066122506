import { useMemo } from 'react';
import { showError } from '../../../../lib/utils';
import { useDeleteLocation, useRestoreLocation } from '../../../domains/locations/location.service';
import { LocationTable, ILocationTableProps } from './location-table.component';
import { useTableFilterConfig } from '../../../_core/table/utils/table-utils';

export const LocationTableContainer: React.FC<ILocationTableProps> = (props) => {
  const { deleteLocation, loading: deleting } = useDeleteLocation();
  const { restoreLocation, loading: restoring } = useRestoreLocation();

  const [filterConfig, filterFunc] = useTableFilterConfig({ activeStatusFilter: true });
  const locations = useMemo(() => props.locations.filter(filterFunc), [filterFunc, props.locations]);

  return (
    <LocationTable
      {...props}
      {...{
        deleteLocation: (props) => deleteLocation(props).catch((err) => showError('Unable to delete location', err)),
        deleting,
        restoreLocation: (props) => restoreLocation(props).catch((err) => showError('Unable to restore location', err)),
        restoring,
        locations,
        filterConfig
      }}
    />
  );
};
