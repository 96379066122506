import { SelfServeRequestGroupForm, ISelfServeRequestGroupFormValues } from './self-serve-request-group-form.component';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IRequestGroupOverview } from '../../../../lib/types';
import { useSelfServeRequest } from '../request-group.service';

interface ISelfServeRequestGroupContainer extends IFormWithStepperProps {
  group: IRequestGroupOverview;
}

export const SelfServeRequestGroupNestedContainer = ({
  group,
  onError,
  onSuccess
}: ISelfServeRequestGroupContainer) => {
  const { selfServeRequest } = useSelfServeRequest(group);

  const handleAccept = async ({ name, email }: ISelfServeRequestGroupFormValues) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      if (await selfServeRequest({ name, email })) {
        onSuccess?.('Check your email for your request invite');
      }
    } catch (err) {
      const errorMessage = (err as Error).message.toLowerCase();

      let msg = 'Failed to request access';
      if (errorMessage.includes('is expired')) msg = 'Bulk request has expired';

      logError(msg, errorMessage);
      onError?.(msg);
    }
  };

  return <SelfServeRequestGroupForm group={group} onSubmit={handleAccept} />;
};
