import { Button } from '../../../_core/button/button.component';
import { IClient, IEntity, IEntityWithMembers } from '../../../../lib/types';
import { useMemo, useState } from 'react';
import { activeStatus, clientActiveStatus } from '../../../../utils/wording.helpers';
import { ContactsTable } from './contacts-table.component';
import { useCompanyLocations } from '../../locations/location.service';
import { useCompanyDepartments } from '../../departments/department.service';
import { IContactsActionButtonProps, ContactsActionButton } from './contacts-action-button';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { EditDepartmentsContainer } from '../../departments/edit/edit-departments-form.container';
import { EditLocationsContainer } from '../../locations/edit/edit-locations-form.container';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { SendClientInvitesContainer } from '../../invite/send-client/send-client-invites-form.container';
import {
  nameableListToString,
  useTableFilterConfig,
  useTableSearchConfig
} from '../../../_core/table/utils/table-utils';
import { sortMethodTuple, useTableSortConfig } from '../../../_core/table/utils/table-sort';
import { ITableOptionsConfig, ITableRowValues } from '../../../_core/table/table.types';

interface IContactsTableNestedContainerProps extends IAdminProps, IPersonalCompanyProps, ITableOptionsConfig<IClient> {
  clients: IClient[];
  editingDisabled?: boolean;
  entity?: IEntity;
  entitiesWithMembers?: IEntityWithMembers[];
}

export const ContactsTableNestedContainer: React.FC<IContactsTableNestedContainerProps> = ({
  bordered,
  clients,
  isAdmin,
  isPersonalCompany,
  editingDisabled,
  entity,
  entitiesWithMembers,
  modeConfig
}) => {
  const [inviteContacts, setInviteContacts] = useState(false);
  const [showEditDepartmentsModal, setShowEditDepartmentsModal] = useState<IClient | null>(null);
  const [showEditLocationsModal, setShowEditLocationsModal] = useState<IClient | null>(null);

  const { departments } = useCompanyDepartments();
  const { locations } = useCompanyLocations();

  const showEntity = !entity;

  const [searchConfig, matches] = useTableSearchConfig({
    items: clients,
    keys: ['name', 'user.name', 'user.email', 'department.name', 'location.name']
  });

  const [filterConfig, filterFunc] = useTableFilterConfig({ activeStatusFilter: true });

  const { sortFunc, ...restSort } = useTableSortConfig({
    defaultSortFunc: (a: IClient, b) => {
      // Sort by status, name, and then email
      if (a.deletedAt && !b.deletedAt) return 1;
      if (!a.deletedAt && b.deletedAt) return -1;

      const aName = a.name ?? a.user.name;
      const bName = b.name ?? b.user.name;

      if (aName && !bName) return -1;
      if (!aName && bName) return 1;

      const nameCompare = (aName ?? '').localeCompare(bName ?? '');
      if (nameCompare) return nameCompare;

      return a.user.email.localeCompare(b.user.email);
    },
    sortMethods: {
      name: sortMethodTuple(),
      deletedAt: sortMethodTuple('string', (d?: Date) => activeStatus(!d)),
      department: sortMethodTuple('string', nameableListToString),
      location: sortMethodTuple('string', nameableListToString)
    }
  });

  // Our filtered and searched output for display
  const contacts = useMemo(() => {
    if (!matches) return null;

    const actionButtonProps: Omit<IContactsActionButtonProps, 'client'> = {
      departments,
      entityId: entity?._id ?? '',
      locations,
      setShowEditDepartmentsModal,
      setShowEditLocationsModal
    };

    const newContacts: ITableRowValues[] = [];
    matches
      ?.filter(filterFunc)
      .sort(sortFunc)
      .forEach((client) => {
        const newContactsValues: ITableRowValues = {
          rowId: client._id,
          values: [
            { children: client.name ?? client.user.name },
            { children: client.user.email },
            { children: clientActiveStatus(client) }
          ]
        };

        if (!isPersonalCompany)
          newContactsValues.values.push(
            { children: nameableListToString(client.department) },
            { children: nameableListToString(client.location) }
          );

        if (showEntity) {
          const entitiesForClient = entitiesWithMembers?.filter(
            (e) => !!e.members.find((m) => m.user._id === client.user._id && !m.deletedAt)
          );
          newContactsValues.values.push({
            children:
              entitiesForClient
                ?.filter((e) => !e.entity.deletedAt)
                .map((e) => e.entity.name)
                .sort((a = '', b = '') => a.localeCompare(b))
                .join(', ') ?? ''
          });
        }

        newContactsValues.values.push({
          children: (
            <ContactsActionButton
              {...actionButtonProps}
              isPersonalCompany={isPersonalCompany}
              client={client}
              editingDisabled={editingDisabled}
            />
          )
        });

        newContacts.push(newContactsValues);
      });

    return newContacts;
  }, [
    matches,
    departments,
    editingDisabled,
    entity?._id,
    locations,
    filterFunc,
    isPersonalCompany,
    showEntity,
    entitiesWithMembers,
    sortFunc
  ]);

  if (!contacts) return null;

  return (
    <>
      <ContactsTable
        {...restSort}
        bordered={bordered}
        isAdmin={isAdmin}
        isPersonalCompany={isPersonalCompany}
        contacts={contacts}
        showEntity={showEntity}
        filterConfig={filterConfig}
        modeConfig={modeConfig}
        searchConfig={searchConfig}
      >
        <Button onClick={() => setInviteContacts(true)} text="Add Contacts" size="large" />
      </ContactsTable>
      <RIDialog open={inviteContacts} setOpen={setInviteContacts}>
        <SendClientInvitesContainer clients={clients} entity={entity} close={() => setInviteContacts(false)} />
      </RIDialog>
      {!!departments && (
        <RIDialog open={!!showEditDepartmentsModal} setOpen={(o) => setShowEditDepartmentsModal((s) => (o ? s : null))}>
          <EditDepartmentsContainer
            departments={departments}
            initialDepartments={showEditDepartmentsModal?.department ?? []}
            targetType={showEditDepartmentsModal && 'code' in showEditDepartmentsModal ? 'client-invite' : 'client'}
            targetId={showEditDepartmentsModal?._id ?? ''}
          />
        </RIDialog>
      )}
      {!!locations && (
        <RIDialog open={!!showEditLocationsModal} setOpen={(o) => setShowEditLocationsModal((s) => (o ? s : null))}>
          <EditLocationsContainer
            locations={locations}
            initialLocations={showEditLocationsModal?.location ?? []}
            targetType={showEditLocationsModal && 'code' in showEditLocationsModal ? 'client-invite' : 'client'}
            targetId={showEditLocationsModal?._id ?? ''}
          />
        </RIDialog>
      )}
    </>
  );
};
