import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { BlockTitles, BlockType, inputClassesLineHeightStr } from './editor.const';
import { IRequiredBlockData, RequiredBlockTool } from './required-block';
import { ICreateTemplateBlockConfig } from './create-template-block';
import _ from 'lodash';
import { AutoSizeTextArea } from './autosizetextarea.component';
import { createRoot } from 'react-dom/client';
import { renderInputPrefix } from './utils/question.utils';

export const LABEL_CONTAINER_CLASS = 'label-container';

export interface RenderWithLabelParams {
  applyMaxWidthAtTopLevelOnly?: boolean;
  maxWidth?: string | null;
  editOnly?: { prefixClass?: string; prefix?: string };
  readOnly: { className?: string; elementType: string };
}

export interface ILabelBlockData extends IRequiredBlockData {
  label?: string;
}

export class LabelBlockTool<T extends ILabelBlockData, U extends ICreateTemplateBlockConfig> extends RequiredBlockTool<
  T,
  U
> {
  private defaultLabel: string;

  constructor(props: BlockToolConstructorOptions<T>) {
    const defaultLabel = props.block?.name ? BlockTitles[props.block.name as BlockType] : 'Label';

    super({ ...props, data: { ...props.data, label: _.unescape(props.data.label ?? defaultLabel) } });
    this.defaultLabel = defaultLabel;
  }

  protected renderWithLabel(params: RenderWithLabelParams = { readOnly: { elementType: 'span' } }): HTMLDivElement {
    super.render();
    if (!this.wrapper) this.wrapper = document.createElement('div');

    const labelContainer = document.createElement('div');
    labelContainer.classList.add('flex', LABEL_CONTAINER_CLASS, 'w-full');
    if (params.maxWidth !== null) labelContainer.classList.add(params.maxWidth ?? 'max-w-[25%]');

    if (this.api.readOnly.isEnabled) {
      const questionLabel = document.createElement(params.readOnly.elementType);
      questionLabel.classList.add('label', ...inputClassesLineHeightStr.split(' '), 'w-full');

      if (params.maxWidth !== null) {
        if (params.maxWidth && !params.applyMaxWidthAtTopLevelOnly) questionLabel.classList.add(params.maxWidth);
        else questionLabel.classList.add('sm:max-w-60', 'md:max-w-96');
      }

      if (params.readOnly.className) {
        questionLabel.classList.remove('sm:text-xs');
        questionLabel.classList.add(params.readOnly.className, 'sm:!leading-6', '!py-1');
      }

      questionLabel.textContent = this.data.label ?? '';
      labelContainer.append(questionLabel);
    } else {
      if (params.editOnly?.prefix) {
        const labelPrefix = renderInputPrefix(params.editOnly.prefix);
        if (params.editOnly.prefixClass) labelPrefix.classList.add(...params.editOnly.prefixClass.split(' '));
        labelContainer.prepend(labelPrefix);
      }

      // Render label input
      const labelInputContainer = document.createElement('div');
      labelInputContainer.classList.add('w-full');
      const root = createRoot(labelInputContainer);
      root.render(
        <AutoSizeTextArea
          hasPrefix={!!params.editOnly?.prefix}
          initialValue={this.data.label ?? ''}
          onChange={(e) => {
            this.data.label = (e as HTMLTextAreaElement).value;
          }}
          variant="underline"
        />
      );
      labelContainer.append(labelInputContainer);
    }

    this.wrapper.prepend(labelContainer);
    return this.wrapper;
  }

  async save(blockContent: HTMLElement): Promise<T> {
    const label = blockContent.querySelector('.label') as HTMLInputElement | undefined;

    const data = await super.save(blockContent);
    return { ...data, label: (label?.value ?? data.label ?? this.defaultLabel)?.trim() };
  }

  validate(_: ILabelBlockData) {
    return true;
  }

  static get sanitize(): Record<string, boolean> {
    // disallow HTML on label
    return { ...super.sanitize, label: false };
  }
}
