import { Loader } from '../../../_core/loader.component';
import { useRequestWithToken } from '../../../domains/request/request.service';
import { IAuthorizedRequestPageWrapper } from './request-page-auth-wrapper.types';
import ErrorPage from '../../error.page';
import { RequestPageContainer } from '../request-page.container';

export const TokenAccessRequestPageContainer = ({
  requestId,
  token
}: IAuthorizedRequestPageWrapper & { token: string }) => {
  const { request, loading, error } = useRequestWithToken({ requestId, token });

  if (error?.message.toLowerCase().includes('invalid token')) return <ErrorPage>Invalid access token</ErrorPage>;

  if (!request)
    if (loading) return <Loader />;
    else return <ErrorPage>Request not found</ErrorPage>;

  if (!request?.editable) return <ErrorPage>You are missing sufficient permissions to view this request</ErrorPage>;
  if (request.deletedAt) return <ErrorPage>Request has been archived</ErrorPage>;

  return <RequestPageContainer clientMode request={request} token={token} />;
};
