import { REQUEST_STATUS, requestStatusLabels } from '../../../../lib/types';
import { StatusIcon } from '../../../_editor/_core/status-icon.component';

interface IRequestGroupStatusIcon {
  inline?: boolean;
  deletedAt?: Date;
  status: REQUEST_STATUS;
}

export const RequestGroupStatusIcon = ({ inline, deletedAt, status }: IRequestGroupStatusIcon) => {
  const statusLabelProps = requestStatusLabels[deletedAt ? REQUEST_STATUS.ARCHIVED : status ?? REQUEST_STATUS.DRAFT];

  return (
    <StatusIcon inline={inline} slim={!inline} backgroundColor={statusLabelProps.color}>
      {statusLabelProps.title}
    </StatusIcon>
  );
};
