import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { Loader } from '../../_core/loader.component';
import { ROUTE_PATHS } from '../../../_routes';

export const LoginPage = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  if (isAuthenticated) return <Navigate to={ROUTE_PATHS.DASHBOARD} />;
  loginWithRedirect();
  return <Loader />;
};
