import { IRequestOverview } from '../../../../lib/types';
import React, { PropsWithChildren, useMemo } from 'react';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { useWindowSize } from 'usehooks-ts';
import {
  ITableHeaderCell,
  ITableOptionsConfig,
  ITableRowClassConfig,
  ITableRowValues
} from '../../../_core/table/table.types';

interface IRequestTableProps
  extends PropsWithChildren,
    IAdminProps,
    IPersonalCompanyProps,
    ITableOptionsConfig<IRequestOverview> {
  requests: IRequestTableRowValues[];
  editingDisabled?: boolean;
  draftsMode?: boolean;
}

export interface IRequestTableRowValues extends ITableRowValues {
  request: IRequestOverview;
}

const baseDraftColSizes: (ITableRowClassConfig | null)[] = [
  { cell: 'w-24 text-center' },
  { cell: 'w-32' },
  { cell: 'w-32' },
  null, // Allow title col to grow
  { cell: 'w-26' },
  { cell: 'w-20' },
  { cell: 'w-24' },
  { cell: 'w-24' },
  { cell: 'w-40' }
];

const baseInboxColSizes: (ITableRowClassConfig | null)[] = [
  { cell: 'w-24 text-center' },
  { cell: 'w-20 text-center' },
  { cell: 'w-32' },
  { cell: 'w-32' },
  null, // Allow title col to grow
  { cell: 'w-26' },
  { cell: 'w-20 text-center' },
  { cell: 'w-32 text-center' },
  { cell: 'w-24' },
  { cell: 'w-24' },
  { cell: 'w-40' }
];

export const RequestTable: React.FC<IRequestTableProps> = ({ children, draftsMode, loading, requests, ...rest }) => {
  const headers: ITableHeaderCell[] = [{ content: 'Last Update', sortKey: 'updatedAt' }];
  if (!draftsMode) headers.push({ content: 'Sent At', sortKey: 'sentAt' });
  headers.push(
    { content: 'Contact', sortKey: 'assignedTo' },
    { content: 'Company', sortKey: 'entity' },
    { content: 'Title', sortKey: 'name' },
    { content: 'Type', sortKey: 'requestType' },
    { content: 'Deadline', sortKey: 'deadline' }
  );
  if (!draftsMode) headers.push({ content: 'Completion', sortKey: 'completion' });
  headers.push({ content: 'Owner', sortKey: 'createdBy' }, { content: 'Status', sortKey: 'status' }, { content: '' });

  const { width } = useWindowSize();
  const isExtraSmallWindow = useMemo(() => width < 1280, [width]);
  const isSmallWindow = useMemo(() => width < 1368, [width]);
  const isLargeWindow = useMemo(() => width > 1500, [width]);

  const rowClasses = useMemo(() => {
    if (isExtraSmallWindow) return [{ cell: 'w-20 text-center' }];
    if (isSmallWindow) return draftsMode ? baseDraftColSizes : baseInboxColSizes;

    // Handle large window sizes
    const sizes = [...(draftsMode ? baseDraftColSizes : baseInboxColSizes)];

    // Update contact and company cols
    const contactIndex = draftsMode ? 1 : 2;
    sizes[contactIndex] = isLargeWindow ? { cell: 'w-40' } : { cell: 'w-36' };
    sizes[contactIndex + 1] = isLargeWindow ? { cell: 'w-48' } : { cell: 'w-40' };

    // Update owner col
    sizes[sizes.length - 3] = isLargeWindow ? { cell: 'w-32' } : { cell: 'w-28' };

    // Update deadline col
    sizes[sizes.length - (draftsMode ? 4 : 5)] = isLargeWindow ? { cell: 'w-32' } : { cell: 'w-28' };

    return sizes;
  }, [draftsMode, isExtraSmallWindow, isLargeWindow, isSmallWindow]);

  return (
    <>
      <TableWithActions
        {...rest}
        loading={loading || !requests}
        headers={headers}
        rowClasses={rowClasses}
        values={requests}
      >
        {children}
      </TableWithActions>
    </>
  );
};
