import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';
import { IRequestGroupOverview } from '../../../../lib/types';

export interface ISelfServeRequestGroupFormValues {
  name: string;
  email: string;
}

interface ISelfServeRequestGroupFormProps extends IRIFormProps<ISelfServeRequestGroupFormValues> {
  group: IRequestGroupOverview;
}

export const SelfServeRequestGroupForm = ({ group, onSubmit }: ISelfServeRequestGroupFormProps) => {
  const validateForm = (values: ISelfServeRequestGroupFormValues) => {
    const errors: FormikErrors<ISelfServeRequestGroupFormValues> = {};

    if (!values.name.trim()) errors.name = 'Required';
    if (!values.email) errors.email = 'Required';

    return errors;
  };

  return (
    <Formik
      initialValues={{ name: '', email: '' }}
      onSubmit={(values) => onSubmit({ name: values.name.trim(), email: values.email.trim() })}
      validate={validateForm}
    >
      {(props: FormikProps<ISelfServeRequestGroupFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <div>
            <p className="font-light text-sm mb-4">
              Enter your information below to request access to{' '}
              <span className="font-medium">{group.company?.name + "'s"}</span> request:{' '}
              <span className="font-bold">{group.name}</span>
            </p>
            <p className="font-light text-sm mb-4">The invite will be sent to your email.</p>
          </div>
          <FormInputWithLabel id="email" name="email" label="Email" value={props.values.email} type="email" />
          <FormInputWithLabel id="name" name="name" label="Name" value={props.values.name} />
          <FormActionsRow>
            <FormButton loading={props.isSubmitting} text="Request Access" />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
