import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { TableCell } from './table-cell.component';
import { TableCheckbox } from './table-checkbox.component';
import { ITableHeaderRowProps, ITableRowClassConfig } from './table.types';

export const TableHeader = ({
  favoritable,
  onSortSelect,
  onSelect,
  rowClasses,
  selected,
  selectable,
  sortedKeys,
  values
}: ITableHeaderRowProps) => {
  return (
    <thead className="text-xs text-gray-900 uppercase bg-gray-300">
      <tr>
        {favoritable && <TableCell header rowClass={rowClasses?.[0]} />}
        {selectable && <TableCheckbox header checked={selected} onChange={onSelect} />}
        {values.map(({ content, sortKey }, i) => {
          if (favoritable && !i) return;

          const rowClass: ITableRowClassConfig = rowClasses?.length && rowClasses.length > i ? rowClasses[i] || {} : {};
          if (!rowClass.cell) rowClass.cell = '';
          if (sortKey) rowClass.cell += ' cursor-pointer hover:bg-gray-200';
          const sorted = sortedKeys && sortKey && sortKey in sortedKeys ? sortedKeys[sortKey] : null;

          return (
            <TableCell header key={`table-header-${i}`} rowClass={rowClass}>
              <div
                onClick={sortKey ? () => onSortSelect?.({ sortKey }) : undefined}
                className={`flex gap-2 items-center`}
              >
                <span>{content}</span>
                {sortKey ? (
                  sorted === 'asc' ? (
                    <ChevronUpIcon height={12} width={12} />
                  ) : sorted === 'desc' ? (
                    <ChevronDownIcon height={12} width={12} />
                  ) : (
                    <ChevronUpDownIcon height={12} width={12} />
                  )
                ) : undefined}
              </div>
            </TableCell>
          );
        })}
      </tr>
    </thead>
  );
};
