import { Button } from '../../../_core/button/button.component';
import { IEntity } from '../../../../lib/types';
import { useMemo, useState } from 'react';
import { EntityTable, IEntityTableRowValues } from './entity-table.component';
import { useCompanyLocations } from '../../../domains/locations/location.service';
import { useCompanyDepartments } from '../../../domains/departments/department.service';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { EditDepartmentsContainer } from '../../../domains/departments/edit/edit-departments-form.container';
import { EditLocationsContainer } from '../../../domains/locations/edit/edit-locations-form.container';
import { CreateEntityStepper } from '../../../domains/entities/create/create-entity-stepper.component';
import { activeStatus } from '../../../../utils/wording.helpers';
import { EntityActionButton, IEntityActionButtonProps } from './entity-action-button';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { IEntityTableClientModeProps } from './entity-table.types';
import {
  nameableListToString,
  useTableFilterConfig,
  useTableSearchConfig
} from '../../../_core/table/utils/table-utils';
import { UpdateClientEntitiesContainer } from '../../../domains/clients/update-entities/update-client-entities-form.container';
import {
  nameAndStatusSort,
  sortMethodTuple,
  truthyStringSort,
  useTableSortConfig
} from '../../../_core/table/utils/table-sort';
import { ITableOptionsConfig } from '../../../_core/table/table.types';

interface IEntityTableNestedContainerProps extends IAdminProps, IPersonalCompanyProps, ITableOptionsConfig<IEntity> {
  clientModeConfig?: IEntityTableClientModeProps;
  entities?: IEntity[];
}

export const EntityTableNestedContainer: React.FC<IEntityTableNestedContainerProps> = ({
  bordered,
  clientModeConfig,
  entities,
  isAdmin,
  isPersonalCompany,
  modeConfig
}) => {
  const [createEntity, setCreateEntity] = useState(false);
  const [editingEntities, setEditingEntities] = useState(false);
  const [showEditDepartmentsModal, setShowEditDepartmentsModal] = useState<IEntity | null>(null);
  const [showEditLocationsModal, setShowEditLocationsModal] = useState<IEntity | null>(null);

  const { departments } = useCompanyDepartments();
  const { locations } = useCompanyLocations();

  const [searchConfig, matches] = useTableSearchConfig({
    items: entities,
    keys: ['name', 'company.name', 'department.name', 'location.name', 'mainContact.name', 'mainContact.email']
  });

  const [filterConfig, filterFunc] = useTableFilterConfig({ activeStatusFilter: true });
  const { sortFunc, ...restSort } = useTableSortConfig({
    sortMethods: {
      name: sortMethodTuple(),
      deletedAt: sortMethodTuple('string', (deletedAt?: Date) => activeStatus(!deletedAt)),
      mainContact: sortMethodTuple((a: IEntity, b, sorted) =>
        truthyStringSort(sorted === 'desc', a.mainContact?.name, b.mainContact?.name)
      ),
      department: sortMethodTuple('string', nameableListToString),
      location: sortMethodTuple('string', nameableListToString)
    },
    defaultSortFunc: nameAndStatusSort
  });

  const entityRows = useMemo(() => {
    const actionButtonProps: Omit<IEntityActionButtonProps, 'entity'> = {
      departments,
      locations,
      setShowEditDepartmentsModal,
      setShowEditLocationsModal
    };

    return matches
      ?.filter(filterFunc)
      .sort(sortFunc)
      .map((entity) => {
        const row: IEntityTableRowValues = {
          entity,
          values: [
            { children: entity.name },
            { children: activeStatus(!entity.deletedAt) },
            { children: entity.mainContact?.name }
          ]
        };

        if (!isPersonalCompany)
          row.values.push(
            { children: nameableListToString(entity.department) },
            { children: nameableListToString(entity.location) }
          );

        row.values.push({
          children: (
            <EntityActionButton
              {...actionButtonProps}
              entity={entity}
              isPersonalCompany={isPersonalCompany}
              clientModeConfig={clientModeConfig}
            />
          )
        });

        return row;
      });
  }, [departments, locations, matches, filterFunc, isPersonalCompany, clientModeConfig, sortFunc]);

  if (!entityRows) return null;

  return (
    <>
      <EntityTable
        {...restSort}
        bordered={bordered}
        isAdmin={isAdmin}
        isPersonalCompany={isPersonalCompany}
        entities={entityRows}
        filterConfig={filterConfig}
        modeConfig={modeConfig}
        searchConfig={searchConfig}
      >
        <>
          {clientModeConfig ? (
            <Button onClick={() => setEditingEntities(true)} text="Add Company" size="large" />
          ) : (
            <Button onClick={() => setCreateEntity(true)} text="Create Company" size="large" />
          )}
        </>
      </EntityTable>
      <RIDialog open={createEntity} setOpen={setCreateEntity}>
        <CreateEntityStepper isPersonalCompany={isPersonalCompany} close={() => setCreateEntity(false)} />
      </RIDialog>
      {!!departments && !!showEditDepartmentsModal && (
        <RIDialog open={!!showEditDepartmentsModal} setOpen={(o) => setShowEditDepartmentsModal((s) => (o ? s : null))}>
          <EditDepartmentsContainer
            departments={departments}
            targetId={showEditDepartmentsModal._id}
            targetType="entity"
            initialDepartments={showEditDepartmentsModal.department}
          />
        </RIDialog>
      )}
      {!!locations && !!showEditLocationsModal && (
        <RIDialog open={!!showEditLocationsModal} setOpen={(o) => setShowEditLocationsModal((s) => (o ? s : null))}>
          <EditLocationsContainer
            locations={locations}
            targetId={showEditLocationsModal._id}
            targetType="entity"
            initialLocations={showEditLocationsModal.location}
          />
        </RIDialog>
      )}
      {!!clientModeConfig && (
        <RIDialog open={editingEntities} setOpen={setEditingEntities}>
          <UpdateClientEntitiesContainer
            client={clientModeConfig.client}
            members={clientModeConfig.members ?? []}
            close={() => setEditingEntities(false)}
          />
        </RIDialog>
      )}
    </>
  );
};
