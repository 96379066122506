import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import download from 'downloadjs';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';

import { showError, sleep } from '../../../lib/utils';
import { Button } from '../../_core/button/button.component';
import { useDownloadAllAssets } from '../assets/asset.service';
import { EditorApolloProvider } from '../../_editor/_core/editor-apollo-provider';
import { UploadData, UploadFileType, UploadItemData } from '../../_editor/upload/upload.types';
import { IRequest, IRequestBlock, IRequestTokenParams, ITemplate } from '../../../lib/types';
import { getDownloadFileName } from '../assets/download-utils';
import { EditorSaveResult } from '../../_editor/_core/types';

interface IDownloadRequestAssetsButton extends IRequestTokenParams {
  forceLabelsOnDocProvide?: boolean; // Force use labels on doc provide downloads
  hasFiles: boolean;
  ignoreSpacing?: boolean;
  onSave: () => Promise<EditorSaveResult>;
}

const DownloadRequestAssetsButton = ({
  forceLabelsOnDocProvide,
  hasFiles,
  ignoreSpacing,
  onSave,
  requestToken
}: IDownloadRequestAssetsButton) => {
  const { downloadAssets } = useDownloadAllAssets(requestToken);

  const findFilesToDownload = useCallback(
    (blocks?: IRequestBlock[], fileType?: UploadFileType) => {
      const foundFiles: (UploadItemData & { forceLabel?: boolean })[] = [];

      const updateFoundFiles = (data: object, type?: string | null) => {
        Object.entries(data).forEach(([key, value]) => {
          if (!value) return;

          if (key === 'uploads') {
            const foundUploads = (data as UploadData).uploads;
            if (!fileType || type === fileType || (fileType === 'upload' && type === 'uploadList')) {
              foundFiles.push(
                ...(forceLabelsOnDocProvide && type === 'docProvide'
                  ? foundUploads.map((u) => ({ ...u, forceLabel: true }))
                  : foundUploads)
              );
            }
          } else if (typeof value === 'object') {
            updateFoundFiles(value, 'type' in value ? value['type'] : type);
          }
        });
      };
      blocks?.forEach(({ data }) => updateFoundFiles(data));

      return foundFiles.filter((f) => f.fileId);
    },
    [forceLabelsOnDocProvide]
  );

  const handleDownload = useCallback(
    async (fileType?: UploadFileType) => {
      try {
        const { data: savedData } = await onSave();
        if (savedData) {
          const items = findFilesToDownload(savedData.blocks as IRequestBlock[], fileType);
          try {
            const results = await downloadAssets({ items });
            for (let i = 0; i < results.length; i++) {
              const file = results[i];
              const downloadName = getDownloadFileName({ itemData: items[i] });

              if (file) {
                try {
                  download(file.data, downloadName);
                } catch (err) {
                  showError('Failed to download ' + downloadName, err as Error);
                }

                await sleep(100);
              }
            }
          } catch (err) {
            showError('Failed to download files', err as Error);
          }
        } else toast.error('Failed to find files to download', { id: 'skipped-download' });
      } catch (err) {
        showError('Failed to download files', err as Error);
      }
    },
    [downloadAssets, findFilesToDownload, onSave]
  );

  return (
    <Button
      slim
      className={ignoreSpacing ? undefined : 'ml-4'}
      hideEndMargin
      icon={<ArrowDownTrayIcon height={24} width={24} />}
      size="fit"
      fit="right"
      variant="secondary"
      disabled={!hasFiles}
      dropdown={{
        options: [
          { text: 'Download provided files', onClick: () => handleDownload('docProvide') },
          { text: 'Download uploaded files', onClick: () => handleDownload('upload') }
        ],
        hideIcon: true,
        ignoreRelativeContainer: true,
        className: 'max-w-52'
      }}
    />
  );
};

interface IDownloadRequestAssetsButtonContainer extends IRequestTokenParams {
  forceLabelsOnDocProvide?: boolean;
  ignoreSpacing?: boolean;
  onSave: () => Promise<EditorSaveResult>;
  request?: IRequest;
  template?: ITemplate;
}

export const DownloadRequestAssetsButtonContainer = ({
  onSave,
  request,
  template,
  ...rest
}: IDownloadRequestAssetsButtonContainer) => {
  const hasFiles = useMemo(() => {
    const findFileToDownload = (data?: object | null): boolean => {
      return (
        !!data &&
        Object.entries(data).some(([key, value]) => {
          if (key === 'uploads') {
            return !!(data as UploadData).uploads.length;
          } else if (typeof value === 'object') {
            return findFileToDownload(value);
          }

          return false;
        })
      );
    };

    const blocks = request?.blocks ?? template?.blocks;
    if (!blocks) return false;

    return !!blocks?.some(({ data }) => findFileToDownload(data));
  }, [request]);

  return (
    <EditorApolloProvider>
      <DownloadRequestAssetsButton hasFiles={hasFiles} onSave={onSave} {...rest} />
    </EditorApolloProvider>
  );
};
