import { useCallback } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { IRequestGroup, IRequestGroupOverview, Identifiable } from '../../../lib/types';
import { mergeCacheLists, showError } from '../../../lib/utils';
import {
  ICreateRequestGroupCallParams,
  IGetRequestGroupsResults,
  IRequestGroupResult,
  ISendRequestGroupParams,
  IUpdateRequestGroupCallParams
} from './request-group.types';
import {
  CREATE_REQUEST_GROUP,
  DELETE_REQUEST_GROUP,
  DELIVER_REQUEST_GROUP,
  GET_REQUEST_GROUP,
  GET_REQUEST_GROUP_OVERVIEW,
  GET_REQUEST_GROUPS,
  RESTORE_REQUEST_GROUP,
  SELF_SERVE_REQUEST_GROUP,
  UPDATE_REQUEST_GROUP
} from './request-group.queries';
import { IArchivableProps } from '../../../lib/query.types';
import { readRequestGroupsCache, writeRequestGroupsCache } from './request-group.utils';
import toast from 'react-hot-toast';

export const useLazyRequestGroup = (variables: Identifiable) => {
  const [getRequestGroup, data] = useLazyQuery<IRequestGroupResult, Identifiable>(GET_REQUEST_GROUP, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  return { ...data, getRequestGroup };
};

export const useRequestGroup = (variables: Identifiable) => {
  const { data, ...rest } = useQuery<IRequestGroupResult, Identifiable>(GET_REQUEST_GROUP, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  return { ...rest, requestGroup: data?.requestGroup };
};

export const useRequestGroupOverview = (variables: Identifiable) => {
  const { data, ...rest } = useQuery<{ requestGroupOverview: IRequestGroupOverview }, Identifiable>(
    GET_REQUEST_GROUP_OVERVIEW,
    {
      fetchPolicy: 'cache-and-network',
      variables
    }
  );

  return { ...rest, requestGroup: data?.requestGroupOverview };
};

export const useRequestGroups = (variables: IArchivableProps) => {
  const { data, ...rest } = useQuery<IGetRequestGroupsResults, IArchivableProps>(GET_REQUEST_GROUPS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 60 * 1000,
    variables
  });

  return { ...rest, requestGroups: data?.requestGroups };
};

export const useCreateRequestGroup = () => {
  const [mutation, rest] = useMutation<{ createRequestGroup: IRequestGroup }>(CREATE_REQUEST_GROUP, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.createRequestGroup) {
        cache.writeQuery({
          query: GET_REQUEST_GROUP,
          data: { requestGroup: data.createRequestGroup } as IRequestGroupResult
        });

        const requestGroups = readRequestGroupsCache({ cache });
        if (requestGroups)
          writeRequestGroupsCache({
            cache,
            requestGroups: [...requestGroups, data.createRequestGroup]
          });
      }
    }
  });

  const createRequestGroup = useCallback(
    (variables: ICreateRequestGroupCallParams) => mutation({ variables }),
    [mutation]
  );

  return { createRequestGroup, ...rest };
};

export const useUpdateRequestGroup = ({ _id }: Identifiable) => {
  const [mutation, rest] = useMutation<{ updateRequestGroup: IRequestGroup }>(UPDATE_REQUEST_GROUP, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateRequestGroup) {
        cache.writeQuery({
          query: GET_REQUEST_GROUP,
          data: { requestGroup: data.updateRequestGroup } as IRequestGroupResult,
          variables: { _id }
        });

        const requestGroups = readRequestGroupsCache({ cache });
        if (requestGroups)
          writeRequestGroupsCache({
            cache,
            requestGroups: mergeCacheLists(requestGroups, [data.updateRequestGroup])
          });
      }
    },
    onError: (error) => showError(`Failed to update request`, error)
  });

  const updateRequestGroup = useCallback(
    (variables: IUpdateRequestGroupCallParams) => mutation({ variables: { ...variables, _id } }),
    [_id, mutation]
  );

  return { updateRequestGroup, ...rest };
};

export const useDeleteRequestGroup = () => {
  const [mutation, rest] = useMutation<{ deleteRequestGroup: IRequestGroup }, Identifiable>(DELETE_REQUEST_GROUP, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const requestGroups = readRequestGroupsCache({ cache });
        if (requestGroups)
          writeRequestGroupsCache({
            cache,
            requestGroups: mergeCacheLists(requestGroups, [data.deleteRequestGroup])
          });
      }
    }
  });

  const deleteRequestGroup = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { deleteRequestGroup, ...rest };
};

export const useRestoreRequestGroup = () => {
  const [mutation, rest] = useMutation<{ restoreRequestGroup: IRequestGroup }, Identifiable>(RESTORE_REQUEST_GROUP, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const requestGroups = readRequestGroupsCache({ cache });
        if (requestGroups)
          writeRequestGroupsCache({
            cache,
            requestGroups: mergeCacheLists(requestGroups, [data.restoreRequestGroup])
          });
      }
    }
  });

  const restoreRequestGroup = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { restoreRequestGroup, ...rest };
};

export const useDeliverRequestGroup = () => {
  const [mutation, rest] = useMutation<{ deliverRequestGroup: IRequestGroupOverview }, ISendRequestGroupParams>(
    DELIVER_REQUEST_GROUP,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }, { variables }) => {
        if (data?.deliverRequestGroup) {
          cache.writeQuery({
            query: GET_REQUEST_GROUP,
            data: { requestGroup: data.deliverRequestGroup } as IRequestGroupResult,
            variables: { _id: variables?._id }
          });

          const requestGroups = readRequestGroupsCache({ cache });
          if (requestGroups)
            writeRequestGroupsCache({
              cache,
              requestGroups: mergeCacheLists(requestGroups, [data.deliverRequestGroup])
            });
        }
      },
      onCompleted: () => toast.success('Bulk request sent successfully!'),
      onError: (error) => {
        const errMsg = error.message.toLowerCase();
        if (errMsg.includes('blocks cannot be empty'))
          toast.error('Bulk request template must have content prior to sending to contacts');
        else if (errMsg.includes('no new requests to send')) toast.error('No new contacts added to bulk request');
        else showError('Failed to send requests', error);
      }
    }
  );

  const deliverRequestGroup = useCallback((variables: ISendRequestGroupParams) => mutation({ variables }), [mutation]);

  return { deliverRequestGroup, ...rest };
};

export const useSelfServeRequest = ({ _id }: Identifiable) => {
  const [mutation, rest] = useMutation<{ selfServe: boolean }>(SELF_SERVE_REQUEST_GROUP, {
    context: { serializationKey: 'MUTATION', tracked: true }
  });

  const selfServeRequest = useCallback(
    (variables: { name: string; email: string }) => mutation({ variables: { ...variables, _id } }),
    [mutation]
  );

  return { selfServeRequest, ...rest };
};
