import { BlockType } from '../editor.const';

export const ROMAN_NUMERAL_KEYS = [
  '',
  'c',
  'cc',
  'ccc',
  'cd',
  'd',
  'dc',
  'dcc',
  'dccc',
  'cm',
  '',
  'x',
  'xx',
  'xxx',
  'xl',
  'l',
  'lx',
  'lxx',
  'lxxx',
  'xc',
  '',
  'i',
  'ii',
  'iii',
  'iv',
  'v',
  'vi',
  'vii',
  'viii',
  'ix'
];

export const TITLE_BLOCK_TYPES: string[] = [
  BlockType.BranchMulti,
  BlockType.BranchYesNo,
  BlockType.Checkbox,
  BlockType.DocProvide,
  BlockType.DocUpload,
  BlockType.DocUploadList,
  BlockType.Link,
  BlockType.Question,
  BlockType.QuestionGroup,
  BlockType.Radio,
  BlockType.RepeatSection,
  BlockType.Section,
  BlockType.YesNo
];
