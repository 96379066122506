import { gql } from '@apollo/client';
import { COMPANY_OVERVIEW_FRAGMENT } from '../companies/company.queries';

export const TEMPLATE_OVERVIEW_FRAGMENT = gql`
  fragment TemplateOverview on Template {
    _id
    blockType
    company {
      ...CompanyOverview
    }
    description
    deletedAt
    title
    type
    updatedAt
  }

  ${COMPANY_OVERVIEW_FRAGMENT}
`;

const TEMPLATE_FRAGMENT = gql`
  fragment TemplateDetails on Template {
    ...TemplateOverview
    blocks {
      data
      type
    }
  }

  ${TEMPLATE_OVERVIEW_FRAGMENT}
`;

export const GET_TEMPLATE = gql`
  query GetTemplate($_id: ID!) {
    template(_id: $_id) {
      ...TemplateDetails
    }
  }

  ${TEMPLATE_FRAGMENT}
`;

export const GET_TEMPLATES = gql`
  query GetTemplates($types: [TemplateType!]!) {
    templates(types: $types) {
      ...TemplateOverview
    }
  }

  ${TEMPLATE_OVERVIEW_FRAGMENT}
`;

export const CLEAR_TEMPLATE = gql`
  mutation ClearTemplate($_id: ID!) {
    clearTemplate(_id: $_id) {
      ...TemplateDetails
    }
  }

  ${TEMPLATE_FRAGMENT}
`;

export const CLEAR_GLOBAL_TEMPLATE = gql`
  mutation ClearGlobalTemplate($_id: ID!) {
    clearGlobalTemplate(_id: $_id) {
      ...TemplateDetails
    }
  }

  ${TEMPLATE_FRAGMENT}
`;

export const CREATE_GLOBAL_TEMPLATE = gql`
  mutation CreateGlobalTemplate(
    $blocks: [RequestBlockTemplateInput!]!
    $version: String!
    $type: TemplateType!
    $title: String!
    $description: String
  ) {
    createGlobalTemplate(blocks: $blocks, version: $version, type: $type, title: $title, description: $description) {
      ...TemplateOverview
    }
  }

  ${TEMPLATE_OVERVIEW_FRAGMENT}
`;

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate(
    $blocks: [RequestBlockTemplateInput!]!
    $version: String!
    $type: TemplateType!
    $title: String!
    $description: String
  ) {
    createTemplate(blocks: $blocks, version: $version, type: $type, title: $title, description: $description) {
      ...TemplateOverview
    }
  }

  ${TEMPLATE_OVERVIEW_FRAGMENT}
`;

export const DELETE_GLOBAL_TEMPLATE = gql`
  mutation DeleteGlobalTemplate($_id: ID!) {
    deleteGlobalTemplate(_id: $_id) {
      ...TemplateOverview
    }
  }

  ${TEMPLATE_OVERVIEW_FRAGMENT}
`;

export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($_id: ID!) {
    deleteTemplate(_id: $_id) {
      ...TemplateOverview
    }
  }

  ${TEMPLATE_OVERVIEW_FRAGMENT}
`;

export const RESTORE_TEMPLATE = gql`
  mutation RestoreTemplate($_id: ID!) {
    restoreTemplate(_id: $_id) {
      ...TemplateOverview
    }
  }

  ${TEMPLATE_OVERVIEW_FRAGMENT}
`;

export const UPDATE_GLOBAL_TEMPLATE = gql`
  mutation UpdateGlobalTemplate(
    $_id: ID!
    $title: String
    $description: String
    $isGlobal: Boolean
    $blocks: [RequestBlockTemplateInput!]
  ) {
    updateGlobalTemplate(_id: $_id, title: $title, description: $description, isGlobal: $isGlobal, blocks: $blocks) {
      ...TemplateDetails
    }
  }

  ${TEMPLATE_FRAGMENT}
`;

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($_id: ID!, $title: String, $description: String, $blocks: [RequestBlockTemplateInput!]) {
    updateTemplate(_id: $_id, title: $title, description: $description, blocks: $blocks) {
      ...TemplateDetails
    }
  }

  ${TEMPLATE_FRAGMENT}
`;
