import React, { PropsWithChildren } from 'react';

import { ITemplateBaseDetails } from '../../../../lib/types';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { ITableHeaderCell, ITableOptionsConfig, ITableRowValues } from '../../../_core/table/table.types';

interface ITemplateTableProps
  extends PropsWithChildren,
    IAdminProps,
    IPersonalCompanyProps,
    ITableOptionsConfig<ITemplateBaseDetails> {
  templates: ITemplateTableRowValues[];
  showBlockType?: boolean;
}

export interface ITemplateTableRowValues extends ITableRowValues {
  template: ITemplateBaseDetails;
}

export const TemplateTable: React.FC<ITemplateTableProps> = ({ children, showBlockType, templates, ...rest }) => {
  const headers: ITableHeaderCell[] = [
    { content: 'Name', sortKey: 'title' },
    { content: 'Last Updated', sortKey: 'updatedAt' },
    { content: 'Status' },
    { content: 'Type', sortKey: 'type' },
    { content: 'Description' }
  ];
  if (showBlockType) headers.push({ content: 'Block Type', sortKey: 'blockType' });
  headers.push({ content: '' });

  return (
    <>
      <TableWithActions {...rest} selectable loading={!templates} headers={headers} values={templates}>
        {children}
      </TableWithActions>
    </>
  );
};
