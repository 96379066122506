import { BlockToolConstructorOptions } from '@editorjs/editorjs';
import { IUploadBlockConfig, Upload } from './upload.component';
import { UploadData } from './upload.types';
import { BlockTitles, BlockType } from '../_core/editor.const';
import { REQUIRED_TUNE } from '../_core/required-block';

export class DocProvide extends Upload {
  constructor(props: BlockToolConstructorOptions<UploadData, IUploadBlockConfig>) {
    const { config, data, ...rest } = props;
    if (!config) throw new Error('Provide config was empty');

    const newConfig: IUploadBlockConfig = { ...config, mode: 'provide' };
    const newData = { ...data, label: data.label ?? 'Please download' };

    super({ ...rest, config: newConfig, data: newData });

    // Configure settings
    this.settings = this.settings.filter(({ name }) => name !== REQUIRED_TUNE);
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.DocProvide],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" /></svg>'
    };
  }
}
