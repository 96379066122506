// Components
import { LandingPage } from './landing-page.component';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../_routes';
import { useAuth0WithRedirect } from '../../_auth/auth.utils';

export const LandingPageContainer = () => {
  const { loginWithRedirect, logout, error, isLoading: isAuthLoading, isAuthenticated } = useAuth0WithRedirect();

  const [loading, setLoading] = useState(false);

  const startAuth0 = (screen_hint?: 'login' | 'signup') => {
    setLoading(true);
    loginWithRedirect(screen_hint, { appState: { returnTo: ROUTE_PATHS.DASHBOARD } }).finally(() =>
      setTimeout(() => setLoading(false), 500)
    );
  };

  useEffect(() => {
    if (error) {
      console.error('Authentication error', error);
      logout();
    }
  }, [error, logout]);

  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      setTimeout(() => {
        navigate(ROUTE_PATHS.DASHBOARD);
        setLoading(false);
      }, 500);
    }
  }, [isAuthenticated, navigate]);

  return <LandingPage onSignIn={startAuth0} isLoading={isAuthLoading || loading} />;
};
