import { Form, Formik, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IClient, IDepartment, ILocation } from '../../../../lib/types';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';

export interface IUpdateClientFormValues {
  department: IMultiSelectOption[];
  location: IMultiSelectOption[];
  name: string;
}

interface IUpdateClientFormProps extends IRIFormProps<IUpdateClientFormValues> {
  client: IClient;
  departments: IDepartment[];
  locations: ILocation[];
}

export const UpdateClientForm = ({ client, departments, locations, onSubmit }: IUpdateClientFormProps) => {
  return (
    <Formik
      initialValues={
        {
          name: client.name ?? client.user.name ?? '',
          department: client.department.map((d) => ({
            value: d._id,
            label: d.name,
            secondaryLabel: d.deletedAt ? 'INACTIVE' : undefined
          })),
          location: client.location.map((l) => ({
            value: l._id,
            label: l.name,
            secondaryLabel: l.deletedAt ? 'INACTIVE' : undefined
          }))
        } as IUpdateClientFormValues
      }
      onSubmit={(values) => onSubmit({ ...values, name: values.name.trim() })}
    >
      {(props: FormikProps<IUpdateClientFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel
            id="name"
            name="name"
            label="Name"
            placeholder="Edit contact name"
            value={props.values.name}
          />
          <FormInputWithLabel
            id="department"
            name="department"
            label="Departments"
            placeholder="Edit departments"
            value={props.values.department}
            type="multiselect"
            multiSelectProps={{
              options: departments.filter((d) => !d.deletedAt).map((d) => ({ label: d.name, value: d._id })),
              selectAll: true
            }}
          />
          <FormInputWithLabel
            id="location"
            name="location"
            label="Locations"
            placeholder="Edit locations"
            value={props.values.location}
            type="multiselect"
            multiSelectProps={{
              options: locations.filter((d) => !d.deletedAt).map((l) => ({ label: l.name, value: l._id })),
              selectAll: true
            }}
          />
          <FormButton loading={props.isSubmitting} text="Update" />
        </Form>
      )}
    </Formik>
  );
};
