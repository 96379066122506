import { useMemo, useRef } from 'react';
import Select, { ActionMeta, CSSObjectWithLabel, MultiValue, SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';
import { TagSize } from '../tag.component';
import { reactSelectOptionClasses } from './input-consts';

export interface IInputProps {
  containerClass?: string;
  disabled?: boolean;
  id?: string;
  placeholder?: string;
  value: IMultiSelectOption[];
  setValue: (_: IMultiSelectOption[]) => void;

  // Form specific fields
  hasBeenTouched?: boolean;
  setHasBeenTouched?: (_: boolean) => void;
  setTouched?: (_: boolean) => void;
}

export interface ICreatableOption {
  __isNew__?: boolean;
}

export interface IMultiSelectOption extends ICreatableOption {
  disabled?: boolean;
  group?: string;
  label?: string;
  secondaryLabel?: string;
  value: string;
}

export interface IMultiSelectInputProps {
  canAdd?: boolean;
  error?: JSX.Element;
  groupOrder?: string[];
  inputClasses?: string;
  noMargin?: boolean;
  nullable?: boolean;
  onAdd?: (_: string) => void;
  options?: IMultiSelectOption[];
  placeholder?: string;
  selectAll?: boolean;
  singleSelect?: boolean;
  skipSort?: boolean;
  tagSize?: TagSize;
}

const selectAllOptions = ({
  options,
  value,
  ...rest
}: {
  onChange: (v: MultiValue<IMultiSelectOption> | SingleValue<IMultiSelectOption>) => void;
  options?: IMultiSelectOption[] | { options: IMultiSelectOption[]; label: string }[];
  value: IMultiSelectOption[];
}) => {
  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(value);
  valueRef.current = value;

  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: 'Select All'
  };

  const isSelectAllSelected = valueRef.current.length === options?.length;

  const isOptionSelected = (option: IMultiSelectOption) =>
    valueRef.current.some(({ value }) => value === option.value) || isSelectAllSelected;

  const onChange = (
    newValue: MultiValue<IMultiSelectOption> | SingleValue<IMultiSelectOption>,
    actionMeta: ActionMeta<IMultiSelectOption>
  ) => {
    const { action, option, removedValue } = actionMeta;

    if (action === 'select-option' && option?.value === selectAllOption.value) {
      const optionValues: IMultiSelectOption[] = [];
      options?.forEach((o) => {
        if ('options' in o) {
          optionValues.push(...o.options);
        } else optionValues.push(o);
      });
      rest.onChange(optionValues);
    } else if (
      (action === 'deselect-option' && option?.value === selectAllOption.value) ||
      (action === 'remove-value' && removedValue.value === selectAllOption.value)
    ) {
      rest.onChange([]);
    } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected) {
      const optionValues: IMultiSelectOption[] = [];
      options?.forEach((o) => {
        if ('options' in o) {
          optionValues.push(...o.options);
        } else optionValues.push(o);
      });
      rest.onChange(optionValues);
      rest.onChange(optionValues.filter(({ value }) => value !== option?.value));
    } else {
      rest.onChange(newValue || []);
    }
  };

  return {
    isOptionSelected,
    options: options ? [selectAllOption, ...options] : [],
    value,
    onChange
  };
};
export const MultiSelectInput = ({
  canAdd,
  error,
  onAdd,
  options,
  placeholder,
  selectAll,
  setValue,
  singleSelect,
  skipSort,
  groupOrder,
  value
}: IMultiSelectInputProps & IInputProps) => {
  const sortOp = (a: IMultiSelectOption, b: IMultiSelectOption) => {
    const aTitle = a.label ?? a.secondaryLabel ?? a.value;
    const bTitle = b.label ?? b.secondaryLabel ?? b.value;
    return aTitle.localeCompare(bTitle);
  };

  // Transform options into groups for react-select
  const transformedOptions = useMemo(() => {
    if (groupOrder) {
      // Create group entries
      const groups: { options: IMultiSelectOption[]; label: string }[] = groupOrder.map((label) => ({
        label,
        options: []
      }));

      // Fill group entries
      options?.forEach((o) => {
        if (o.group) {
          const groupIndex = groups.findIndex((g) => g.label === o.group);
          groups[groupIndex].options.push(o);
        }
      });

      // Return groups only if some groups have data
      const nonEmptyGroups = groups.filter((g) => g.options.length);
      if (nonEmptyGroups.length) return nonEmptyGroups.map((g) => ({ ...g, options: g.options.sort(sortOp) }));
    }

    if (skipSort) return options;
    return options?.sort(sortOp);
  }, [groupOrder, options, skipSort]);

  const onChange = (v: MultiValue<IMultiSelectOption> | SingleValue<IMultiSelectOption>) => {
    setValue(Array.isArray(v) ? [...v] : [v]);
  };
  const props = {
    ...(selectAll && !singleSelect
      ? selectAllOptions({ onChange, options: transformedOptions, value })
      : { onChange, options: transformedOptions, value }),
    isClearable: true,
    isSearchable: true,
    isMulti: !singleSelect,
    closeMenuOnSelect: !singleSelect,
    placeholder: placeholder,
    styles: { placeholder: (base: CSSObjectWithLabel) => ({ ...base, fontWeight: 300, fontSize: '0.875rem' }) },
    classNames: {
      control: () => `${error ? '!border-red-300' : ''} !rounded-md !min-h-8.5`,
      clearIndicator: () => '!py-1.5',
      dropdownIndicator: () => '!py-1.5',
      option: () => reactSelectOptionClasses,
      placeholder: error ? () => '!text-red-400' : undefined,
      // TODO: Need to replace this by replacing border with ring inset to match other inputs
      valueContainer: () => '!min-h-8.625 !py-0'
    },
    formatOptionLabel: (o: IMultiSelectOption) => (
      <div>
        <span>{!o.label && !o.secondaryLabel ? o.value : o.label}</span>
        {!!o.secondaryLabel && <span className="text-xs"> ({o.secondaryLabel})</span>}
      </div>
    ),
    isOptionDisabled: (o: IMultiSelectOption) => !!o.disabled,
    filterOption: (o: { data: IMultiSelectOption }, input: string) => {
      if (!input) return true;
      const searchString = input.toLowerCase();
      return (
        !!o.data.label?.toLowerCase().includes(searchString) ||
        !!o.data.value.toLowerCase().includes(searchString) ||
        !!o.data.secondaryLabel?.toLowerCase().includes(searchString)
      );
    }
  };

  return (
    <>
      {canAdd ? (
        <Creatable {...props} onCreateOption={onAdd ? (n: string) => onAdd(n) : undefined} />
      ) : (
        <Select {...props} />
      )}
      {error}
    </>
  );
};
