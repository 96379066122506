import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/dist/types/types';
import { OutputData } from '@editorjs/editorjs';

export interface IBlockConversionData {
  label?: string;
  required?: boolean;
  options?: string[];
}

export interface EditorSaveResult {
  data?: OutputData;
  hasChanged?: boolean;
}

export type InputAppearanceVariant = 'box' | 'underline';

export interface IBlockConfigOutline {
  onToggleToolbox: () => void;
  readOnly: boolean;
  toolbarWrapperId: string;
}

export interface IDragItem {
  index: number;
  id: string;
  type: string;
}

export type DragState =
  | { type: 'idle' }
  | { type: 'preview'; container: HTMLElement }
  | { type: 'is-dragging' }
  | { type: 'is-dragging-over'; closestEdge: Edge | null };

export const IDLE: DragState = { type: 'idle' };
