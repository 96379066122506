import { IClient } from '../../../../lib/types';
import React, { PropsWithChildren } from 'react';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { ITableHeaderCell, ITableOptionsConfig, ITableRowValues } from '../../../_core/table/table.types';

interface IContactsTableProps
  extends PropsWithChildren,
    IAdminProps,
    IPersonalCompanyProps,
    ITableOptionsConfig<IClient> {
  bordered?: boolean;
  contacts: ITableRowValues[];
  showEntity?: boolean;
}

export const ContactsTable: React.FC<IContactsTableProps> = ({
  bordered,
  children,
  isPersonalCompany,
  contacts,
  showEntity,
  ...rest
}) => {
  const headers: ITableHeaderCell[] = [
    { content: 'Name', sortKey: 'name' }, // TODO: Need to support multipel sort keys, so we can sort by name and user.name
    { content: 'Email' },
    { content: 'Status', sortKey: 'deletedAt' }
  ];

  if (!isPersonalCompany)
    headers.push({ content: 'Departments', sortKey: 'department' }, { content: 'Locations', sortKey: 'location' });

  if (showEntity) headers.push({ content: 'Company' });
  headers.push({ content: '' });

  return (
    <>
      <TableWithActions {...rest} bordered={bordered} loading={!contacts} headers={headers} values={contacts}>
        {children}
      </TableWithActions>
    </>
  );
};
