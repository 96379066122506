import { FormContainer } from '../../../_core/form/form-container.component';
import { useState } from 'react';
import { SelfServeRequestGroupNestedContainer } from './self-serve-request-group-form-nested.container';
import { useRequestGroupOverview } from '../request-group.service';
import { Loader } from '../../../_core/loader.component';

interface ISelfServeRequestGroupContainer {
  groupId: string;
}

export const SelfServeRequestGroupContainer = ({ groupId }: ISelfServeRequestGroupContainer) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { requestGroup, error } = useRequestGroupOverview({ _id: groupId });

  return (
    <FormContainer
      hideLogo
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Request Access"
    >
      {error || (!!requestGroup && !requestGroup.canSelfServe) ? (
        <h1 className="mb-4 text-error">Failed to find self-serve request option</h1>
      ) : requestGroup ? (
        requestGroup.isExpired ? (
          <h1 className="mb-4 text-error">Bulk request access has expired</h1>
        ) : (
          <SelfServeRequestGroupNestedContainer
            group={requestGroup}
            onError={(e) => setSubmitError(e ?? null)}
            onSuccess={async (msg) => {
              setSubmitError(null);
              setSuccess(msg ?? null);
            }}
          />
        )
      ) : (
        <div className="flex justify-center">
          <Loader />
        </div>
      )}
    </FormContainer>
  );
};
