import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { LocalTitleBlockTool } from '../_core/local-title-block/local-title-block.component';
import { ICreateTemplateBlockConfig } from '../_core/create-template-block';
import { IRenderOpenToggleParams, renderOpenToggle, renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';
import { Section } from '../section';
import { Radio, RadioData } from '../radio';
import { ISectionData } from '../section/section.types';
import { localTitleBlockExport, localTitleBlockImport } from '../_core/utils/block.utils';
import { ILabelBlockData } from '../_core/label-block';
import { getSectionElementIds, renderSectionTemplateHeader } from '../_core/utils/section.utils';
import { BlockTitles, BlockType } from '../_core/editor.const';

const BRANCH_SECTION_CLASS = 'branch-section';
const BRANCH_SECTION_ELEMENT_WRAPPER_CLASS = 'branch-section-element-wrapper';

export interface IBranchData extends ILabelBlockData {
  questionData: RadioData;
  sectionsData: ISectionData[];
}

export class BranchRadio extends LocalTitleBlockTool<IBranchData, ICreateTemplateBlockConfig> {
  private open: boolean[];
  private sections: Section[];
  private radio: Radio;

  private blockWrapperId: string;
  private branchSectionIdPrefix: string;
  private canEditOptions: boolean;
  private selectedSection: number;

  constructor(props: BlockToolConstructorOptions<Partial<IBranchData>> & { canEditOptions: boolean }) {
    const { data, ...rest } = props;
    super({
      ...rest,
      data: {
        ...data,
        questionData: {
          ...data.questionData,
          options: data.questionData?.options ?? [],
          value: data.questionData?.value ?? null
        },
        sectionsData:
          data.sectionsData?.map((sd: Partial<ISectionData>) => ({
            ...sd,
            outputData: { ...sd.outputData, blocks: sd.outputData?.blocks ?? [] }
          })) ?? ([] as ISectionData[])
      }
    });

    this.blockWrapperId = 'block-wrapper-' + this.uid;
    this.branchSectionIdPrefix = 'branch-section-' + this.uid + '-';
    this.canEditOptions = rest.canEditOptions ?? true;

    // Created nested controlled block components
    this.sections = this.data.sectionsData.map((sd) => new Section({ ...rest, data: sd }));
    this.open = this.sections.map(() => true);
    this.radio = new Radio({ ...rest, data: this.data.questionData });

    // Show first branch section by default in edit mode
    this.selectedSection = Math.max(
      this.data.questionData.options.findIndex((o) => o === this.data.questionData.value),
      0
    );
  }

  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.BranchMulti],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M248 106.6c18.9-9 32-28.3 32-50.6c0-30.9-25.1-56-56-56s-56 25.1-56 56c0 22.3 13.1 41.6 32 50.6l0 98.8c-2.8 1.3-5.5 2.9-8 4.7l-80.1-45.8c1.6-20.8-8.6-41.6-27.9-52.8C57.2 96 23 105.2 7.5 132S1.2 193 28 208.5c1.3 .8 2.6 1.5 4 2.1l0 90.8c-1.3 .6-2.7 1.3-4 2.1C1.2 319-8 353.2 7.5 380S57.2 416 84 400.5c19.3-11.1 29.4-32 27.8-52.8l50.5-28.9c-11.5-11.2-19.9-25.6-23.8-41.7L88 306.1c-2.6-1.8-5.2-3.3-8-4.7l0-90.8c2.8-1.3 5.5-2.9 8-4.7l80.1 45.8c-.1 1.4-.2 2.8-.2 4.3c0 22.3 13.1 41.6 32 50.6l0 98.8c-18.9 9-32 28.3-32 50.6c0 30.9 25.1 56 56 56s56-25.1 56-56c0-22.3-13.1-41.6-32-50.6l0-98.8c2.8-1.3 5.5-2.9 8-4.7l80.1 45.8c-1.6 20.8 8.6 41.6 27.8 52.8c26.8 15.5 61 6.3 76.5-20.5s6.3-61-20.5-76.5c-1.3-.8-2.7-1.5-4-2.1l0-90.8c1.4-.6 2.7-1.3 4-2.1c26.8-15.5 36-49.7 20.5-76.5S390.8 96 364 111.5c-19.3 11.1-29.4 32-27.8 52.8l-50.6 28.9c11.5 11.2 19.9 25.6 23.8 41.7L360 205.9c2.6 1.8 5.2 3.3 8 4.7l0 90.8c-2.8 1.3-5.5 2.9-8 4.6l-80.1-45.8c.1-1.4 .2-2.8 .2-4.3c0-22.3-13.1-41.6-32-50.6l0-98.8z"/></svg>'
    };
  }

  private onOpenToggle({ index, renderParams }: { index: number; renderParams: IRenderOpenToggleParams }) {
    this.open[index] = !this.open[index];

    const ids = getSectionElementIds(this.branchSectionIdPrefix, index);
    document.getElementById(ids.element)?.classList.toggle('hidden');
    renderOpenToggle({
      ...renderParams,
      open: this.open[index],
      replaceSelector: '#' + ids.titleWrapper,
      onOpenToggle: () => this.onOpenToggle({ index, renderParams })
    });
  }

  private destroyCurrSections() {
    // Destroy old section editors, so new instances are created properly on demand
    this.sections.forEach((s) => {
      try {
        s.editor.current?.destroy();
        s.editor.current = undefined;
      } catch (err) {}
    });
  }

  private renderSection({
    applyToDocumentImmediately,
    sectionIndex = this.selectedSection
  }: {
    applyToDocumentImmediately?: boolean;
    sectionIndex?: number;
  }) {
    if (sectionIndex >= 0) {
      // Create new section content and treat as always open
      const ids = getSectionElementIds(this.branchSectionIdPrefix, sectionIndex);

      // Create section element if it has content
      let sectionElement: HTMLDivElement | null = null;
      if (this.data.sectionsData[sectionIndex].outputData?.blocks.length || !this.api.readOnly.isEnabled) {
        sectionElement = this.sections[sectionIndex].renderSectionContent();
        sectionElement.id = ids.element;
        sectionElement.classList.remove('hidden');
        sectionElement.classList.add(BRANCH_SECTION_CLASS);
      }

      // Return the section element unless it should be immediately inserted into the document (ie. on section re-render)
      const templateHeader = renderSectionTemplateHeader({
        elementWrapperClass: BRANCH_SECTION_ELEMENT_WRAPPER_CLASS,
        idPrefix: this.branchSectionIdPrefix,
        onOpenToggle: (p) => this.onOpenToggle(p),
        open: this.open[sectionIndex],
        readOnly: this.api.readOnly.isEnabled,
        sectionElement,
        sectionIndex,
        sectionTitleText: this.api.readOnly.isEnabled
          ? undefined
          : this.canEditOptions
          ? this.data.questionData.options[sectionIndex]
          : `${sectionIndex + 1} - ${this.data.questionData.options[sectionIndex]}`
      });

      if (applyToDocumentImmediately) {
        const currSectionWrapper = document.querySelector(
          `#${this.blockWrapperId} .${BRANCH_SECTION_ELEMENT_WRAPPER_CLASS}`
        );

        // Replace current element or add initial section
        const replaceCurrSection = !!currSectionWrapper && this.api.readOnly.isEnabled;
        if (replaceCurrSection) currSectionWrapper.replaceWith(templateHeader);
        else document.getElementById(this.blockWrapperId)?.appendChild(templateHeader);
      } else return templateHeader;
    }
  }

  private handleBranchAnswer(answer: string | null, renderInDom = true) {
    if (this.data.questionData.value !== answer) {
      // Update answer and re-render selected section
      this.data.questionData.value = answer;
      this.selectedSection = this.data.questionData.options.findIndex((o) => o === this.data.questionData.value);

      if (renderInDom) {
        this.destroyCurrSections();
        this.renderSection({ applyToDocumentImmediately: true, sectionIndex: this.selectedSection });
      }

      // Mark section as answered
      // TODO: Possibly determine required success from whole picture of required content in branch. A similar functionality might be needed section blocks.
      this.data.filled = !!answer;
      this.toggleRequiredIndicator({ checked: this.data.filled });
    }
  }

  render() {
    // Setup block wrapper with label line, and section content beneath
    this.renderWithLocalTitle(this.api.readOnly ? undefined : { maxWidth: null, readOnly: { elementType: 'span' } });

    if (!this.wrapper) this.wrapper = document.createElement('div');
    this.wrapper.classList.add('flex', 'rounded', 'gap-4');

    const blockWrapper = document.createElement('div');
    blockWrapper.id = this.blockWrapperId;

    // Render higher config (ie. Radio input)
    const radioContainer = this.radio.renderRadioInput({
      targetWrapper: this.wrapper,
      canEditOptions: this.canEditOptions,
      afterChange: (v) => this.handleBranchAnswer(v),
      onOptionsChange: (options) => {
        if (options.length > this.data.sectionsData.length) {
          // A section added
          this.data.sectionsData.push({ outputData: { blocks: [] } });
          this.sections.push(
            new Section({
              data: { outputData: { blocks: [] } },
              api: this.api,
              readOnly: this.config.readOnly,
              block: this.block,
              config: this.config
            })
          );

          this.open.push(true);

          this.data.questionData.options = options;
          this.renderSection({ applyToDocumentImmediately: true, sectionIndex: this.sections.length - 1 });
        } else if (options.length < this.data.sectionsData.length) {
          // A section was removed
          const sectionWrappers = document.querySelectorAll(
            `#${this.blockWrapperId} .${BRANCH_SECTION_ELEMENT_WRAPPER_CLASS}`
          );
          sectionWrappers.forEach((s) => s.remove());

          const optionsRemoved = this.data.questionData.options.reduce((acc: number[], curr, i) => {
            if (!options.includes(curr)) {
              acc.push(i);

              if (this.data.questionData.value === curr) {
                this.data.questionData.value = null;
                this.radio.setValue(null);
                this.selectedSection = -1;

                // Mark section as answered
                // TODO: Possibly determine required success from whole picture of required content in branch. A similar functionality might be needed section blocks.
                this.data.filled = false;
                this.toggleRequiredIndicator({ checked: this.data.filled });
              }
            }
            return acc;
          }, []);

          this.destroyCurrSections();
          this.data.sectionsData = this.data.sectionsData.filter((_, i) => !optionsRemoved.includes(i));
          this.sections = this.sections.filter((_, i) => !optionsRemoved.includes(i));
          this.open = this.open.filter((_, i) => !optionsRemoved.includes(i));
          this.data.questionData.options = options;

          // Re-render all sections
          this.sections.forEach((_, sectionIndex) =>
            this.renderSection({ applyToDocumentImmediately: true, sectionIndex })
          );
        } else if (options.some((o, i) => o !== this.data.questionData.options[i])) {
          const newSections: Section[] = [];
          const newSectionData: ISectionData[] = [];
          const newOpen: boolean[] = [];
          options.forEach((o, i) => {
            const fetchIndex =
              this.data.questionData.options[i] === o
                ? i
                : this.data.questionData.options.findIndex((curr) => o === curr);

            newSections.push(this.sections[fetchIndex]);
            newSectionData.push(this.data.sectionsData[fetchIndex]);
            newOpen.push(this.open[fetchIndex]);
          });

          this.destroyCurrSections();
          this.sections = newSections;
          this.data.sectionsData = newSectionData;
          this.open = newOpen;
          this.data.questionData.options = options;

          // Section order  changed
          const sectionWrappers = document.querySelectorAll(
            `#${this.blockWrapperId} .${BRANCH_SECTION_ELEMENT_WRAPPER_CLASS}`
          );
          sectionWrappers.forEach((s) => s.remove());

          // Re-render all sections
          this.sections.forEach((_, sectionIndex) =>
            this.renderSection({ applyToDocumentImmediately: true, sectionIndex })
          );
        }
      }
    });

    // Add required UX components, if element is required
    const indicator = this.renderRequiredIndicator();
    const completedCheckbox = this.renderCompletedCheckbox();

    if (radioContainer) {
      if (indicator) radioContainer.appendChild(indicator);
      if (completedCheckbox) radioContainer.appendChild(completedCheckbox);
      this.wrapper.appendChild(radioContainer);
    }

    // Add elements in block order
    blockWrapper.appendChild(this.wrapper);

    // Show all sections in edit mode, or if an answer is selected in client mode and there are blocks in the section
    if (this.data.questionData.value && this.api.readOnly.isEnabled) {
      const sectionContent = this.renderSection({});
      if (sectionContent) blockWrapper.appendChild(sectionContent);
    } else if (!this.api.readOnly.isEnabled && !this.config.readOnly) {
      const sectionContent: (string | Node)[] = [];
      for (let i = 0; i < this.sections.length; i++) {
        const section = this.renderSection({ sectionIndex: i });
        if (section) sectionContent.push(section);
      }

      blockWrapper.append(...sectionContent);
    }

    return renderWithBlockFocusWrapper(blockWrapper);
  }

  async save(blockContent: HTMLDivElement): Promise<IBranchData> {
    // Save data
    const newData = await super.save(blockContent);
    newData.questionData = await this.radio.save(blockContent);
    newData.sectionsData = await Promise.all(this.sections.map((s) => s.save(blockContent)));

    // Update local data and data for each section instance
    this.data = newData;
    this.sections.forEach((s, i) => s.updateData(this.data.sectionsData[i]));

    return newData;
  }

  static get conversionConfig() {
    return { export: localTitleBlockExport, import: localTitleBlockImport };
  }

  static get sanitize() {
    // Allo section data items to control their own HTML rules
    return { ...super.sanitize, sectionsData: true };
  }
}
export class BranchYesNo extends BranchRadio {
  constructor(props: BlockToolConstructorOptions<IBranchData>) {
    const { data, ...rest } = props;
    super({
      ...rest,
      canEditOptions: false,
      data: {
        ...data,
        questionData: {
          ...data.questionData,
          options: ['Yes', 'No']
        },
        sectionsData:
          data.sectionsData?.map((sd: Partial<ISectionData>) => ({
            ...sd,
            outputData: { ...sd.outputData, blocks: sd.outputData?.blocks ?? [] }
          })) ??
          ([
            { completed: false, filled: false, required: false, outputData: { blocks: [] } },
            { completed: false, filled: false, required: false, outputData: { blocks: [] } }
          ] as ISectionData[])
      }
    });
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.BranchYesNo],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M80 104a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm80-24c0 32.8-19.7 61-48 73.3l0 38.7c0 17.7 14.3 32 32 32l160 0c17.7 0 32-14.3 32-32l0-38.7C307.7 141 288 112.8 288 80c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3l0 38.7c0 53-43 96-96 96l-48 0 0 70.7c28.3 12.3 48 40.5 48 73.3c0 44.2-35.8 80-80 80s-80-35.8-80-80c0-32.8 19.7-61 48-73.3l0-70.7-48 0c-53 0-96-43-96-96l0-38.7C19.7 141 0 112.8 0 80C0 35.8 35.8 0 80 0s80 35.8 80 80zm208 24a24 24 0 1 0 0-48 24 24 0 1 0 0 48zM248 432a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"/></svg>'
    };
  }
}
