import { useEffect, useRef, useState } from 'react';
import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';

import { Tag } from '../../_core/tag.component';
import { DragState, IDLE } from '../_core/types';
import { createPortal } from 'react-dom';
import { DropIndicator } from '../../_core/drop-indicator';

interface IRadioOption {
  tagClass: string;
  onRemove: () => void;
  tag: string;
  i: number;
}

export const RadioOption = ({ i, onRemove, tag, tagClass }: IRadioOption) => {
  // Setup drag and drop handlers
  const ref = useRef<HTMLDivElement | null>(null);
  const [dragState, setDragState] = useState<DragState>(IDLE);
  useEffect(() => {
    const element = ref.current;
    const dragHandle = ref.current;
    if (element && dragHandle) {
      combine(
        draggable({
          element: dragHandle,
          getInitialData: () => ({ tag }),
          onGenerateDragPreview({ nativeSetDragImage }) {
            setCustomNativeDragPreview({
              nativeSetDragImage,
              getOffset: pointerOutsideOfPreview({ x: '16px', y: '8px' }),
              render: ({ container }) => setDragState({ type: 'preview', container })
            });
          },
          onDragStart: () => setDragState({ type: 'is-dragging' }),
          onDrop: () => setDragState(IDLE)
        }),
        dropTargetForElements({
          element,
          canDrop({ source }) {
            // not allowing dropping on yourself
            if (source.element === element) return false;
            return 'tag' in source.data;
          },
          getData({ input }) {
            const data = { tag };
            return attachClosestEdge(data, { element, input, allowedEdges: ['left', 'right'] });
          },
          getIsSticky: () => true,
          onDragEnter({ self }) {
            const closestEdge = extractClosestEdge(self.data);
            setDragState({ type: 'is-dragging-over', closestEdge });
          },
          onDrag({ self, source }) {
            const isSource = source.element === element;
            if (isSource) {
              setDragState(IDLE);
              return;
            }

            // Only need to update react state if nothing has changed.
            // Prevents re-rendering.
            setDragState((current) => {
              const closestEdge = extractClosestEdge(self.data);
              if (current.type === 'is-dragging-over' && current.closestEdge === closestEdge) {
                return current;
              }
              return { type: 'is-dragging-over', closestEdge };
            });
          },
          onDragLeave: () => setDragState(IDLE),
          onDrop: () => setDragState(IDLE)
        })
      );
    }
  }, [tag]);

  return (
    <>
      <div className="relative inline-flex">
        <Tag
          key={'tag-' + tag + i}
          ref={ref}
          onRemove={onRemove}
          tag={tag}
          tagClass={tagClass}
          className={dragState.type === 'is-dragging' ? 'opacity-50' : ''}
          size="small"
        />
        {dragState.type === 'is-dragging-over' && dragState.closestEdge ? (
          <DropIndicator edge={dragState.closestEdge} gap={'8px'} />
        ) : null}
      </div>
      {dragState.type === 'preview'
        ? createPortal(<div className="border-solid rounded p-2 bg-white">{tag}</div>, dragState.container)
        : null}
    </>
  );
};
