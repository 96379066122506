import { Form, Formik, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { ILocation } from '../../../../lib/types';
import { ISelectedIDProps } from '../../../../lib/query.types';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';

interface ISelectLocationsFormFieldProps {
  ids: IMultiSelectOption[];
}

interface ISelectLocationsFormProps extends IRIFormProps<ISelectedIDProps> {
  locations: ILocation[];
}

export const SelectLocationsForm = ({ locations, onSubmit }: ISelectLocationsFormProps) => {
  return (
    <Formik
      initialValues={{ ids: [] } as ISelectLocationsFormFieldProps}
      onSubmit={({ ids }) => onSubmit({ ids: ids.map(({ value }) => value) })}
    >
      {(props: FormikProps<ISelectLocationsFormFieldProps>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel
            id="ids"
            name="ids"
            label="Locations"
            placeholder="Select locations"
            value={props.values.ids}
            type="multiselect"
            multiSelectProps={{ options: locations.map((d) => ({ label: d.name, value: d._id })), selectAll: true }}
          />
          <div className="flex row justify-around">
            <FormButton loading={props.isSubmitting} text="Update" />
          </div>
        </Form>
      )}
    </Formik>
  );
};
