import { Form, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { PropsWithChildren } from 'react';
import { ISendCustomRequestMessageParams } from '../../request/request.types';
import _ from 'lodash';

export interface ISendCustomRequestGroupMessageFormParams
  extends Omit<ISendCustomRequestMessageParams, '_id' | 'notify' | 'to'> {
  assignSelf: boolean;
}

interface ISendRequestGroupMessageFormFieldsParams
  extends FormikProps<ISendCustomRequestGroupMessageFormParams>,
    PropsWithChildren {}

export const SendRequestGroupMessageFormFields = ({ children, values }: ISendRequestGroupMessageFormFieldsParams) => {
  return (
    <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
      <FormInputWithLabel
        id="assignSelf"
        name="assignSelf"
        label="Assign me to all new requests"
        value={values.assignSelf}
        type="checkbox"
      />
      <FormInputWithLabel
        id="replyTo"
        name="replyTo"
        label="Reply To:"
        value={values.replyTo}
        type="email"
        placeholder="Enter email"
      />
      <FormInputWithLabel
        id="subject"
        name="subject"
        label="Subject:"
        value={values.subject}
        type="text"
        placeholder="You have received a new message on one of your Request's"
      />
      <FormInputWithLabel
        id="message"
        name="message"
        label="Message:"
        placeholder="Enter your message here..."
        value={values.message}
        type="textarea"
      />
      {children}
    </Form>
  );
};
