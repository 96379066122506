import { IDepartment, Identifiable } from '../../../../lib/types';
import React, { useState } from 'react';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { CreateDepartmentsContainer } from '../../../domains/departments/create/create-departments-form.container';
import { Button } from '../../../_core/button/button.component';
import { activeStatus } from '../../../../utils/wording.helpers';
import { UpdateDepartmentContainer } from '../../../domains/departments/update/update-department-form.container';
import { IAdminProps } from '../../../_core/core.types';
import { ITableHeaderCell, ITableOptionsConfig, ITableRowValues } from '../../../_core/table/table.types';
import { nameAndStatusSort, sortMethodTuple, useTableSortConfig } from '../../../_core/table/utils/table-sort';

export interface IDepartmentTableProps extends IAdminProps, ITableOptionsConfig<IDepartment> {
  departments: IDepartment[];
}

interface IDepartmentTableActionProps {
  deleteDepartment: (_: Identifiable) => void;
  deleting: boolean;
  restoreDepartment: (_: Identifiable) => void;
  restoring: boolean;
}

export const DepartmentTable: React.FC<IDepartmentTableProps & IDepartmentTableActionProps> = ({
  departments,
  deleteDepartment,
  deleting,
  restoreDepartment,
  restoring,
  ...rest
}) => {
  const [createDepartments, setCreateDepartments] = useState(false);
  const [updateDepartment, setUpdateDepartment] = useState<IDepartment | null>(null);

  const headers: ITableHeaderCell[] = [
    { content: 'Name', sortKey: 'name' },
    { content: 'Status', sortKey: 'deletedAt' },
    { content: '' }
  ];

  const { sortFunc, ...restSort } = useTableSortConfig({
    defaultSortFunc: nameAndStatusSort,
    sortMethods: { name: sortMethodTuple(), deletedAt: sortMethodTuple('string', (d?: Date) => activeStatus(!d)) }
  });

  return (
    <>
      <TableWithActions
        {...rest}
        {...restSort}
        bordered
        headers={headers}
        values={
          departments.sort(sortFunc).map(({ _id, name, deletedAt }, i) => {
            const row: ITableRowValues = {
              values: [
                { children: name },
                { children: activeStatus(!deletedAt) },
                {
                  children: (
                    <Button
                      slim
                      loading={deleting || restoring}
                      size="large"
                      text="ACTION"
                      dropdown={{
                        options: [
                          deletedAt
                            ? { text: 'Reactivate', onClick: () => restoreDepartment({ _id }) }
                            : { text: 'Delete', onClick: () => deleteDepartment({ _id }) },
                          { text: 'Edit', onClick: () => setUpdateDepartment(departments[i]) }
                        ]
                      }}
                    />
                  )
                }
              ]
            };

            return row;
          }) ?? []
        }
      >
        <Button onClick={() => setCreateDepartments(true)} text="Add Departments" size="large" />
      </TableWithActions>
      <RIDialog open={createDepartments} setOpen={setCreateDepartments}>
        <CreateDepartmentsContainer close={() => setCreateDepartments(false)} />
      </RIDialog>
      {!!updateDepartment && (
        <RIDialog open={!!updateDepartment} setOpen={(o) => setUpdateDepartment((l) => (o ? l : null))}>
          <UpdateDepartmentContainer {...updateDepartment} />
        </RIDialog>
      )}
    </>
  );
};
