import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OutputData } from '@editorjs/editorjs';

import { ITemplate } from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { ROUTE_PATHS } from '../../../_routes';
import { EditTemplateContainer } from '../../domains/template/edit/edit-template-form.container';
import { DEFAULT_SECTION_FORM_DATA } from '../../_editor/_core/editor.const';
import { useClearTemplate } from '../../domains/template/template.service';
import { EditorPage } from '../editor-page.component';

interface ITemplatePage {
  canSave: boolean;
  template: ITemplate;
}

export const TemplatePage = ({ canSave, template }: ITemplatePage) => {
  const navigate = useNavigate();

  const [editTemplate, setEditTemplate] = useState(false);

  const { clearTemplate, loading: clearingRequest } = useClearTemplate(template);

  const editorData: OutputData = {
    time: Date.now(),
    version: template.version,
    blocks: template.blocks ?? [DEFAULT_SECTION_FORM_DATA]
  };

  return (
    <>
      <EditorPage
        canSave={canSave}
        data={editorData}
        disabled={!!template.deletedAt}
        onBack={() => navigate(ROUTE_PATHS.TEMPLATES)}
        onEdit={() => setEditTemplate(true)}
        template={template}
        title={template.title}
        TitleComponent={
          <h2 className="text-center text-xl text-black font-semibold">
            <span className="text-slate-800">Template -</span> {template.title}
          </h2>
        }
        ActionButtons={({ afterClear, confirmClear }) => (
          <div className="inline-block">
            <Button
              slim
              loading={clearingRequest}
              onClick={() =>
                confirmClear().then((clear) => {
                  if (clear) clearTemplate().then(afterClear);
                })
              }
              size="medium"
              text="Clear"
              variant="primary"
            />
          </div>
        )}
      />
      <RIDialog open={!!editTemplate} setOpen={setEditTemplate}>
        <EditTemplateContainer close={() => setEditTemplate(false)} template={template} />
      </RIDialog>
    </>
  );
};
