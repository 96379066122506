import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../_core/button/button.component';
import { IButtonDropdownOptionProps } from '../../_core/button/button-dropdown-option-component';
import { IRequestGroup, IRequestGroupOverview, REQUEST_STATUS } from '../../../lib/types';
import {
  useDeleteRequestGroup,
  useLazyRequestGroup,
  useRestoreRequestGroup,
  useUpdateRequestGroup
} from './request-group.service';
import { showError } from '../../../lib/utils';
import { ROUTE_PATHS } from '../../../_routes';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { EditRequestGroupContainer } from './edit/edit-request-group.container';

export interface IRequestGroupActionProps {
  group: IRequestGroupOverview;
  hideViewAction?: boolean;
  last?: boolean;
  loading?: boolean;
  onSend?: () => void;
  slim?: boolean;
}

export const RequestGroupActionButton: React.FC<IRequestGroupActionProps> = ({
  group,
  hideViewAction,
  last,
  loading,
  onSend,
  slim
}) => {
  const navigate = useNavigate();

  const [editGroup, setEditGroup] = useState<IRequestGroup | null>(null);

  const { deleteRequestGroup, loading: deletingRequestGroup } = useDeleteRequestGroup();
  const { getRequestGroup, loading: gettingRequestGroup } = useLazyRequestGroup(group);
  const { restoreRequestGroup, loading: restoringRequestGroup } = useRestoreRequestGroup();
  const { updateRequestGroup, loading: updatingRequestGroup } = useUpdateRequestGroup(group);

  const editGroupAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () => getRequestGroup().then((g) => setEditGroup(g.data?.requestGroup ?? null)),
      text: 'Edit Bulk Request'
    }),
    [navigate, group._id]
  );

  const editTemplateAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: () => navigate(ROUTE_PATHS.TEMPLATE + '/' + group.template?._id), text: 'Edit Template' }),
    [navigate, group._id]
  );

  const viewAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () => navigate(ROUTE_PATHS.REQUEST_GROUP + '/' + group._id),
      text: 'View'
    }),
    [navigate, group._id]
  );

  const updateStatusAction: IButtonDropdownOptionProps = useMemo(() => {
    const isClosed = group.status === REQUEST_STATUS.CLOSED;
    return {
      onClick: () => {
        updateRequestGroup({ status: isClosed ? REQUEST_STATUS.SENT : REQUEST_STATUS.CLOSED });
      },
      text: isClosed ? 'Re-open' : 'Close'
    };
  }, [group.status, updateRequestGroup]);

  const copyLinkAction: IButtonDropdownOptionProps = {
    onClick: () => {
      navigator.clipboard.writeText(window.location.host + ROUTE_PATHS.SELF_SERVE + '/' + group._id);
      toast.success('Copied self-serve link');
    },
    text: 'Copy Link'
  };

  const updateLinkStatusAction: IButtonDropdownOptionProps = useMemo(() => {
    return {
      onClick: () => {
        updateRequestGroup({ canSelfServe: !group.canSelfServe });
      },
      text: group.canSelfServe ? 'Disable Self-Serve Link' : 'Enable Self-Serve Link'
    };
  }, [group.canSelfServe, updateRequestGroup]);

  const onRestoreRequest = useCallback(async () => {
    if (group.deletedAt)
      try {
        await restoreRequestGroup(group);
        toast.success(`Restored Bulk Request`);
      } catch (err) {
        showError('Unable to restore bulk request', err as Error);
      }
  }, [group, restoreRequestGroup]);

  const restoreRequestAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onRestoreRequest, text: 'Restore' }),
    [onRestoreRequest]
  );

  const onDeleteRequest = useCallback(async () => {
    try {
      await deleteRequestGroup(group);
      toast.success(`Deleted Bulk Request`);
    } catch (err) {
      showError('Unable to delete bulk request', err as Error);
    }
  }, [deleteRequestGroup, group]);

  const deleteRequestAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onDeleteRequest, text: 'Delete' }),
    [onDeleteRequest]
  );

  const sendAction: IButtonDropdownOptionProps = useMemo(() => ({ onClick: onSend, text: 'Send Requests' }), [onSend]);

  const options = useMemo(() => {
    const newOptions: IButtonDropdownOptionProps[] = [];
    if (!hideViewAction) {
      newOptions.push(viewAction);
      if (group.editable) newOptions.push(editGroupAction, editTemplateAction);
    }

    if (group.editable) {
      if (!group.deletedAt) {
        if (onSend) newOptions.push(sendAction);
        if (group.status && [REQUEST_STATUS.SENT, REQUEST_STATUS.CLOSED].includes(group.status))
          newOptions.push(updateStatusAction);

        newOptions.push(updateLinkStatusAction);
        if (group.canSelfServe) newOptions.push(copyLinkAction);
      }

      newOptions.push(group.deletedAt ? restoreRequestAction : deleteRequestAction);
    }

    return newOptions;
  }, [
    hideViewAction,
    editGroupAction,
    editTemplateAction,
    viewAction,
    group.canSelfServe,
    group.deletedAt,
    group.status,
    restoreRequestAction,
    deleteRequestAction,
    updateStatusAction,
    updateLinkStatusAction,
    onSend,
    sendAction
  ]);

  return (
    <>
      <Button
        slim={slim}
        dropdown={{ options, up: last }}
        text="ACTION"
        size="large"
        loading={
          loading ||
          deletingRequestGroup ||
          restoringRequestGroup ||
          updatingRequestGroup ||
          !!editGroup ||
          gettingRequestGroup
        }
      />
      {!!editGroup && (
        <RIDialog setOpen={(o) => setEditGroup((g) => (o ? g : null))}>
          <EditRequestGroupContainer group={editGroup} close={() => setEditGroup(null)} />
        </RIDialog>
      )}
    </>
  );
};
