import { useMemo, useRef } from 'react';
import EditorJs from '@editorjs/editorjs';

import { RequestPage } from './request-page.component';
import { IRequest, REQUEST_STATUS } from '../../../lib/types';

interface IRequestPageContainer {
  clientMode?: boolean;
  request: IRequest;
  token?: string;
}

export const RequestPageContainer = ({ clientMode, request, token }: IRequestPageContainer) => {
  const ref = useRef<EditorJs>();

  const canSave = useMemo(
    () =>
      !request.deletedAt &&
      (!request.status || ![REQUEST_STATUS.ARCHIVED, REQUEST_STATUS.CLOSED].includes(request.status)),
    [request.deletedAt, request.status]
  );

  return <RequestPage canSave={canSave} editorRef={ref} readOnly={clientMode} request={request} token={token} />;
};
