import { useMemo } from 'react';
import { showError } from '../../../../lib/utils';
import { useTableFilterConfig } from '../../../_core/table/utils/table-utils';
import { useDeleteDepartment, useRestoreDepartment } from '../../../domains/departments/department.service';
import { DepartmentTable, IDepartmentTableProps } from './department-table.component';

export const DepartmentTableContainer: React.FC<IDepartmentTableProps> = (props) => {
  const { deleteDepartment, loading: deleting } = useDeleteDepartment();
  const { restoreDepartment, loading: restoring } = useRestoreDepartment();

  const [filterConfig, filterFunc] = useTableFilterConfig({ activeStatusFilter: true });

  const departments = useMemo(() => props.departments.filter(filterFunc), [filterFunc, props.departments]);

  return (
    <DepartmentTable
      {...props}
      {...{
        deleteDepartment: (props) =>
          deleteDepartment(props).catch((err) => showError('Failed to delete department', err)),
        deleting,
        restoreDepartment: (props) =>
          restoreDepartment(props).catch((err) => showError('Failed to restore department', err)),
        restoring,
        departments,
        filterConfig
      }}
    />
  );
};
