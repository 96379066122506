import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../_routes';
import { RequestTableNestedContainer } from '../DashboardPage/request-table/request-table.nested.container';
import { PageActionWrapper } from '../page-action-wrapper.component';
import { useDeliverRequestGroup, useRequestGroup } from '../../domains/request-group/request-group.service';
import { RequestGroupActionButton } from '../../domains/request-group/request-group-action-button';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { EditRequestGroupContainer } from '../../domains/request-group/edit/edit-request-group.container';
import { useMemo, useState } from 'react';
import { SendRequestGroupMessageContainer } from '../../domains/conversation/send-request-group-message/send-request-group-message.container';
import { REQUEST_NOTIFY_OPTION } from '../../../lib/types';
import { Loader } from '../../_core/loader.component';
import { Button } from '../../_core/button/button.component';

export const BulkRequestPageContainer = () => {
  const navigate = useNavigate();

  const params = useParams();
  const requestGroupId = params.groupId ?? '';

  const { requestGroup, refetch } = useRequestGroup({ _id: requestGroupId });

  const { deliverRequestGroup, loading: deliveringRequestGroup } = useDeliverRequestGroup();

  const [editing, setEditing] = useState(false);
  const [sending, setSending] = useState(false);

  const title = useMemo(
    () =>
      requestGroup?.name ? (
        <h2 className="text-center text-xl text-black font-semibold">
          <span className="text-slate-800">Bulk Request -</span> {requestGroup.name}
        </h2>
      ) : (
        <Loader />
      ),
    [requestGroup?.name]
  );

  if (!requestGroupId) return <Navigate to={ROUTE_PATHS.DASHBOARD} />;
  return (
    <>
      <PageActionWrapper
        onBack={() => navigate(ROUTE_PATHS.DASHBOARD + '?mode=bulk')}
        onEdit={requestGroup?.editable ? () => setEditing(true) : undefined}
        status={requestGroup?.status}
        title={title}
        actionButtons={
          requestGroup?.editable ? (
            <RequestGroupActionButton
              group={requestGroup}
              hideViewAction
              slim
              onSend={() => setSending(true)}
              loading={deliveringRequestGroup}
            />
          ) : undefined
        }
      >
        <div className="mx-auto w-full flex min-h-full flex-1 flex-col items-center bg-white">
          <div className="mx-auto max-w-[90%] w-full">
            <RequestTableNestedContainer favoritable hideModeToggle requests={requestGroup?.requests}>
              {!!requestGroup?.template && requestGroup.editable && (
                <Button
                  onClick={() => navigate(ROUTE_PATHS.TEMPLATE + '/' + requestGroup.template?._id)}
                  text="Edit Template"
                  size="large"
                  className="mt-1"
                />
              )}
            </RequestTableNestedContainer>
          </div>
        </div>
      </PageActionWrapper>
      {!!requestGroup?.editable && (
        <>
          <RIDialog open={editing} setOpen={setEditing}>
            <EditRequestGroupContainer group={requestGroup} close={() => setEditing(false)} />
          </RIDialog>
          <RIDialog open={sending} setOpen={setSending}>
            <SendRequestGroupMessageContainer
              close={() => setSending(false)}
              group={requestGroup}
              forceSendAll
              onSubmit={({ message, notify = [REQUEST_NOTIFY_OPTION.ALL], ...rest }) =>
                deliverRequestGroup({ ...rest, _id: requestGroup._id, customMessage: message, notify }).then(() =>
                  refetch()
                )
              }
            />
          </RIDialog>
        </>
      )}
    </>
  );
};
