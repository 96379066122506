import { GET_REQUEST_GROUPS } from './request-group.queries';
import { IApolloCacheParams } from '../../../lib/cache.types';
import { IGetRequestGroupsResults } from './request-group.types';

export const readRequestGroupsCache = ({ cache }: IApolloCacheParams) =>
  cache.readQuery<IGetRequestGroupsResults>({ query: GET_REQUEST_GROUPS })?.requestGroups;

export const writeRequestGroupsCache = ({ cache, requestGroups }: IApolloCacheParams & IGetRequestGroupsResults) => {
  cache.writeQuery({ query: GET_REQUEST_GROUPS, data: { requestGroups }, variables: { includeArchived: true } });
};
