import { Navigate, useParams } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../_routes';
import { SelfServeRequestGroupContainer } from '../../domains/request-group/self-serve/self-serve-request-group-form.container';

export const SelfServePageContainer = () => {
  const params = useParams();

  if (!params.requestGroupId) return <Navigate to={ROUTE_PATHS.LANDING} />;
  return <SelfServeRequestGroupContainer groupId={params.requestGroupId} />;
};
